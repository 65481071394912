export const PLAN_JSON_LOGIC_KEYS = {
  CUSTOMIZE_INTEGRATED_CLASS: "CUSTOMIZE_INTEGRATED_CLASS",
  CREATE_CUSTOM_CLASS: "CREATE_CUSTOM_CLASS",
  EXPORTS: "EXPORTS",
  MEDIAS_ADD_MEDIA: "MEDIAS_ADD_MEDIA",
  USERS_ADD_USER: "USERS_ADD_USER",
  TEAMS_ADD_TEAM: "TEAMS_ADD_TEAM",
  DOWNGRADE_PLAN_EXPIRATION: "DOWNGRADE_PLAN_EXPIRATION",
  DOWNGRADE_RETROGRADE: "DOWNGRADE_RETROGRADE",
  ENTERPRISE_FEATURE: "ENTERPRISE_FEATURE",
  PRO_BUSINESS_ENTREPRISE: "KEY_PRO_BUSINESS_ENTREPRISE",
};
