import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/loader/Loader";
import InformationsWebUrl from "./InformationsWebUrl";
import { TextField } from "@mui/material";

class FormWebsiteUrl extends React.Component {
  render() {
    const {
      t,
      formData,
      placeHolder,
      handleChangeUrl,
      submitLoading,
      urlLoading,
      urlData,
      enterpriseFeatureIsDisabled,
      onCloseInformations,
      displayInformationUrl,
    } = this.props;
    const fieldsAreDisabled = submitLoading;
    const informationsData =
      urlData &&
      urlData.language_infos &&
      urlData.language_infos.status === "error"
        ? {
            status: "error",
            message: urlData.language_infos.message,
          }
        : urlData;
    return (
      <Fragment>
        <div className="padding-top-20" />
        <div className="position-relative padding-bottom-10">
          <TextField
            placeholder={placeHolder}
            value={formData.url}
            onChange={handleChangeUrl}
            type="text"
            hiddenLabel={true}
            name="url"
            disabled={fieldsAreDisabled}
            sx={{ width: "100%" }}
          />
        </div>
        {urlLoading && (
          <div className="text-align-center position-relative padding-vertical-30">
            <Loader />
          </div>
        )}
        {!urlLoading && urlData && displayInformationUrl && (
          <InformationsWebUrl
            selectedProductSource={this.props.selectedProductSource}
            data={informationsData}
            onCloseClick={onCloseInformations}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(FormWebsiteUrl);
