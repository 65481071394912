import React, { Fragment } from "react";
import "./list.css";
import { withTranslation } from "react-i18next";
import RoundedEmotion from "../ui/RoundedEmotion";
import ColoredScore from "../ui/ColoredScore";
import DateHelper from "../../app/helpers/DateHelper";
import Loader from "../loader/Loader";
import ReportHelper from "../../app/helpers/ReportHelper";
import { uniqueId } from "lodash";
import TooltipWithAction from "../tooltip/TooltipWithAction";
import AjaxKeywordTooltipContent from "../tooltip/AjaxKeywordTooltipContent";
import MediaHelper from "../../app/helpers/MediaHelper";
import translationIcon from "../../res/images/icons/translation-icon.png";
import { Box, Typography } from "@mui/material";

class WorkspaceListItem extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.captionRef = React.createRef();

    this.renderMainColumn = this.renderMainColumn.bind(this);

    this.handleOpen = this.handleOpen.bind(this);
    this.getTextWithTooltips = this.getTextWithTooltips.bind(this);
    this.getProjectKeywordClass = this.getProjectKeywordClass.bind(this);
    this.getKeywordTooltipContent = this.getKeywordTooltipContent.bind(this);
    this.getTextWithTooltipByKeyword =
      this.getTextWithTooltipByKeyword.bind(this);
    this.getTextWithTooltipByKeywordArray =
      this.getTextWithTooltipByKeywordArray.bind(this);
    this.getStartAtContent = this.getStartAtContent.bind(this);
    this.getIsUpdated = this.getIsUpdated.bind(this);
    this.onUpdateScore = this.onUpdateScore.bind(this);
  }

  handleOpen(event) {
    if (
      event.target.nodeName === "A" ||
      event.target.classList.contains("link-blue")
    ) {
      return null;
    }
    if (this.props.highlightedKeyword) {
      return null;
    }
    if (!this.props.getHighlightedSelectionText().length) {
      this.props.onOpenItem(this.props.item.id, () => {
        if (this.captionRef && this.captionRef.current) {
          this.captionRef.current.parentNode.scrollTop =
            this.captionRef.current.offsetTop - 30;
        }
      });
    }
  }

  getTextWithTooltipByKeyword(
    text,
    a_keyword,
    replace_target,
    index,
    itemKeywordClass
  ) {
    const reactStringReplace = require("react-string-replace");
    const replace = `\\b(${replace_target})\\b`;
    const regex = new RegExp(replace, "gi");
    text = reactStringReplace(text, regex, (match, i) => {
      return (
        <TooltipWithAction
          key={`kt-${index}-${i}-${uniqueId()}`}
          content={this.getKeywordTooltipContent(a_keyword)}
          placement="bottom"
        >
          <span className={itemKeywordClass}>{match}</span>
        </TooltipWithAction>
      );
    });
    return text;
  }

  getTextWithTooltipByKeywordArray(keywordsArray, text, itemKeywordClass) {
    const pluralize = require("pluralize");
    keywordsArray.forEach((a_keyword, index) => {
      text = this.getTextWithTooltipByKeyword(
        text,
        a_keyword,
        a_keyword,
        index,
        itemKeywordClass
      );
      const plural_keyword = pluralize.plural(a_keyword);
      text = this.getTextWithTooltipByKeyword(
        text,
        a_keyword,
        plural_keyword,
        index,
        itemKeywordClass
      );
    });
    return text;
  }

  getTextWithTooltips(text) {
    const composedKeywords = ReportHelper.getComposedKeywords(
      this.props.selectedKeywords
    );
    const nonComposedKeywords = ReportHelper.getNonComposedKeywords(
      this.props.selectedKeywords
    );
    const itemKeywordClass = this.getProjectKeywordClass();
    text = this.getTextWithTooltipByKeywordArray(
      composedKeywords,
      text,
      itemKeywordClass
    );
    text = this.getTextWithTooltipByKeywordArray(
      nonComposedKeywords,
      text,
      itemKeywordClass
    );
    return text;
  }

  getProjectKeywordClass() {
    const scoreClass = ReportHelper.getScoreClass(this.props.item.score);
    return scoreClass + " underline-on-hover font-inter-500";
  }

  getKeywordTooltipContent(keyword) {
    const projectId = this.props.projectId;
    if (!projectId) {
      return null;
    }
    return (
      <div className="base-tooltip">
        <AjaxKeywordTooltipContent
          projectId={projectId}
          datasourceId={this.props.datasourceId}
          keyword={keyword}
          onOpenUpdateKeywordClassModal={
            this.props.onOpenUpdateKeywordClassModal
          }
        />
      </div>
    );
  }

  getStartAtContent() {
    const { t, item } = this.props;
    if (item.media.type === MediaHelper.service.Youtube) {
      return (
        <Fragment>
          {t("At")}{" "}
          <a
            href={item.youtube_link}
            rel="noreferrer"
            target="_blank"
            className="link-on-hover color-3a79e6"
          >
            {DateHelper.getSecondsTohhmmss(item.start_at)}
          </a>
        </Fragment>
      );
    }
    if (item.website_link) {
      return "";
    }
    return (
      <Fragment>
        {t("At")} <span className="color-3a79e6">#{item.start_at}</span>
      </Fragment>
    );
  }

  getIsUpdated() {
    const { t, item } = this.props;
    if (item.has_custom_score) {
      return <Fragment>{t(" • Updated score")}</Fragment>;
    }
    return null;
  }

  onUpdateScore(newScore) {
    this.props.onUpdateItemScore(this.props.item, newScore);
  }

  renderMainColumn() {
    const item = this.props.item;
    const originalMode = this.props.displayOriginalReviews;

    return (
      <div className="workspace-content-to-hover">
        <div
          className="workspace-content-hover transition-color"
          onClick={(e) => {
            if (originalMode) {
              e.stopPropagation();
              return;
            }
            this.handleOpen(e);
          }}
        >
          <div className="font-inter-400 line-height-1-53 font-size-13 color-7f7f7f">
            {item.media.title} {this.getStartAtContent() === "" ? "" : "•"}{" "}
            {this.getStartAtContent()}
            {this.getIsUpdated()}
          </div>
          <div
            className={`font-inter-400 margin-top-8 line-height-1-53 ${
              this.props.print ? "font-size-11" : "font-size-15"
            } color-000 highlight-selection-tooltip`}
          >
            {!originalMode && this.getTextWithTooltips(item.context)}
            {originalMode && item.reviewOriginalText}
          </div>
          {!originalMode && item.reviewLanguage != "English" && (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                mt: "4px",
              }}
            >
              <img width="20px" src={translationIcon} alt="Translation" />
              <Typography
                sx={{
                  color: "#757575",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {`Translated from ${item.reviewLanguage} by Sensia`}
              </Typography>
            </Box>
          )}
        </div>
        {!!item.opened && item.opened.status === "loading" && (
          <div className="workspace-loading">
            <Loader />
          </div>
        )}
        {!!item.opened && item.opened.status === "opened" && (
          <Fragment>
            <div className="padding-top-36" />
            <div className="workspace-scroll-content" ref={this.wrapperRef}>
              <div className="content">
                {item.opened.infos.map((aCaption, i) => {
                  if (item.start_at === aCaption.start) {
                    return (
                      <div
                        key={`workspace-list-item-caption-${i}`}
                        ref={this.captionRef}
                        className={`${
                          this.props.print ? "font-size-11" : "font-size-17"
                        } caption selected font-inter-400 line-height-1-53 color-000 highlight-selection-tooltip`}
                      >
                        {this.getTextWithTooltips(aCaption.context)}
                      </div>
                    );
                  }
                  return (
                    <div
                      key={`workspace-list-item-caption-${i}`}
                      className={`caption font-inter-400 line-height-1-53 ${
                        this.props.print ? "font-size-11" : "font-size-17"
                      } color-000 highlight-selection-tooltip`}
                    >
                      {this.getTextWithTooltips(aCaption.context)}
                    </div>
                  );
                })}
              </div>
              <div className="masque" />
              <div className="icon" />
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  render() {
    const { t, scoreTooltipRefs, scoreTooltipRef, scoreTooltipRefIndex } =
      this.props;
    const originalMode = this.props.displayOriginalReviews;
    const item = this.props.item;
    const maxRange = this.props.maxRange;
    let listItemsClasses = ["workspace-list-item", "transition-color"];

    let parsedScore =
      typeof item.score !== "undefined" &&
      item.score !== undefined &&
      item.score !== "-"
        ? parseFloat(item.score).toFixed(2)
        : undefined;

    return (
      <div className={listItemsClasses.join(" ")}>
        <table className="table-layout-fixed">
          <tbody>
            <tr>
              <td width="675" className="column-main">
                {this.renderMainColumn()}
              </td>
              <td className="column-score">
                <div className="padding-left-100">
                  <div className="padding-top-30" />
                </div>
              </td>
              <td className="column-score">
                <div className="padding-left-100">
                  <div className="padding-top-30" />
                  <div
                    className="text-align-right font-inter-500 font-size-20"
                    style={{ width: "60px" }}
                  >
                    {originalMode && parsedScore}
                    {!originalMode && (
                      <ColoredScore
                        initialscore={item.initial_score}
                        score={item.score}
                        maxRange={maxRange}
                        onUpdateScore={this.onUpdateScore}
                        updateScoreLoading={this.props.updateScoreLoading}
                        withHelpTooltip={true}
                        tooltipType="workspace_score"
                        tooltipScoreLibelle={t("score")}
                        tooltipItem={{
                          value: "",
                          occurence: 0,
                          initialscore: item.initial_score,
                          score: item.score,
                        }}
                        scoreTooltipRefs={scoreTooltipRefs}
                        scoreTooltipRef={scoreTooltipRef}
                        scoreTooltipRefIndex={scoreTooltipRefIndex}
                      />
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation()(WorkspaceListItem);
