import React from "react";
import { Route } from "react-router-dom";
import { useToasts } from "react-cooked-bread";
import UserHelper from "../app/helpers/UserHelper";

const PublicRoute = ({ component: Component, ...rest }) => {
  const toastFuncs = useToasts();
  const user = UserHelper.getUser();
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} {...toastFuncs} user={user} />}
    />
  );
};

export default PublicRoute;
