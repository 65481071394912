import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import HttpHelper from "../../../app/helpers/HttpHelper";
import EditModelModal from "../modals/EditModelModal";
import CreateModelModal from "../modals/CreateModelModal";
import ModelSettingsList from "../../../components/list/ModelSettingsList";
import { productService } from "../../../app/services/product.service";
import { modelService } from "../../../app/services/model.service";

class ModelSettingsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideRoundHover: false,
      productSources: [],
      sort: {
        key: null,
        order: null,
      },
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      create: {
        display: false,
        loading: false,
      },
    };

    this.fetchProductSources = this.fetchProductSources.bind(this);
    this.onOpenEditModal = this.onOpenEditModal.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);
    this.onCreateItem = this.onCreateItem.bind(this);
    this.onEditItem = this.onEditItem.bind(this);
    this.onOpenCreate = this.onOpenCreate.bind(this);
    this.onCloseCreate = this.onCloseCreate.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProductSources();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchProductSources() {
    modelService.getProductSources(
      (response) => {
        this.setState({ productSources: response });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onOpenEditModal(item) {
    this.setState({
      edit: {
        display: true,
        item: item,
        loading: false,
        error: null,
      },
    });
  }

  onOpenCreate() {
    this.setState({
      create: {
        display: true,
        loading: false,
      },
    });
  }

  onCloseCreate() {
    this.setState({
      create: {
        display: false,
        loading: false,
      },
    });
  }

  onCloseEditModal() {
    this.setState({
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onEditItem(formData) {
    const { t } = this.props;

    let data = Object.assign({}, formData);
    const selectedClasses = data.selectedClassesArray;
    data.classes = selectedClasses?.map((result) => result.value);
    delete data.selectedClassesArray;

    const selectedProductSources = data.selectedProductSources;
    data.product_sources = selectedProductSources;
    delete data.selectedProductSources;

    productService.editProductModel(
      data,
      (response) => {
        this.props.fetchProducts();
        this.props.addToast(t("Model successfully updated"), {
          type: "success",
          autoDismiss: true,
        });
        this.onCloseEditModal();
      },
      (error) => {
        if (this._ismounted) {
          this.setState({ isLoaded: true });
          let errorMessage = HttpHelper.getErrorMessage(error);
          if (errorMessage == null || errorMessage == undefined) {
            errorMessage = JSON.stringify(error);
          }
          this.props.addToast(errorMessage, {
            type: "error",
            autoDismiss: true,
          });
        }
      }
    );
  }

  onCreateItem(formData) {
    const { t } = this.props;

    productService.createProductModel(
      formData,
      (response) => {
        this.props.fetchProducts();
        this.props.addToast(t("Model successfully created"), {
          type: "success",
          autoDismiss: true,
        });
        this.onCloseCreate();
      },
      (error) => {
        if (this._ismounted) {
          this.setState({ isLoaded: true });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      }
    );
  }

  render() {
    const { t } = this.props;
    const products = this.props.products;
    return (
      <Fragment>
        <div className="font-inter-500 font-size-15 line-height-22 color-7f7f7f">
          {t("View and edit Product Categories.")}
        </div>
        <div className="padding-top-44" />
        <div className="padding-top-25" />
        <button
          onClick={this.onOpenCreate}
          /*disabled={!buttonIsEnabled}*/ className="margin-top-15 button float-right"
        >
          {t("Create Product")}
        </button>
        <table className="sensia-table table-layout-fixed width-percent-100">
          <thead>
            <tr>
              <th width={150}>{t("Product Category")}</th>
              <th width={150} className="hide-760">
                {t("Score Model")}
              </th>
              <th width={150} className="hide-760">
                {t("Feeling Model")}
              </th>
              <th width={150} className="hide-760">
                {t("Selected Classes")}
              </th>
              <th width={30} />
            </tr>
          </thead>
        </table>
        <div className="padding-top-5" />
        {products.map((item, i) => {
          return (
            <ModelSettingsList
              key={`settings-model-list-item-${i}`}
              item={item}
              onOpenEditModal={() => {
                this.onOpenEditModal(item);
              }}
            />
          );
        })}

        {this.state.edit.display && (
          <EditModelModal
            onClose={this.onCloseEditModal}
            loading={this.state.edit.loading}
            item={this.state.edit.item}
            onEditItem={this.onEditItem}
            products={products}
            productSources={this.state.productSources}
          />
        )}
        {this.state.create.display && (
          <CreateModelModal
            onClose={this.onCloseCreate}
            loading={this.state.edit.loading}
            onCreateItem={this.onCreateItem}
            productSources={this.state.productSources}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(ModelSettingsTab);
