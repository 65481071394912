import HttpHelper from "../helpers/HttpHelper";
import { saveAs } from "file-saver";

async function getInvoicesData(account_id, formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/invoice/get-data/" + account_id,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function resendInvoice(invoice_id, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/invoice/resent/" + invoice_id,
    HttpHelper.method.POST,
    null,
    onSuccess,
    onError
  );
}

async function downloadInvoice(invoice_id, onSuccess, onError) {
  const requestOptions = {
    method: HttpHelper.method.GET,
    headers: HttpHelper.authHeader({ "Content-Type": "text/csv" }),
    responseType: "blob",
  };

  const url = HttpHelper.getApiUrl(
    `/api/v1/invoice/download/${invoice_id}`,
    HttpHelper.method.GET,
    null
  );

  const filename = `invoice_${invoice_id}.pdf`;

  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedFileResponse)
    .then((response) => response.blob())
    .then((blob) => {
      if (blob) {
        saveAs(blob, filename);
        HttpHelper.handleFunction(onSuccess);
        return blob;
      }
    })
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
}

export const invoiceService = {
  getInvoicesData,
  resendInvoice,
  downloadInvoice,
};
