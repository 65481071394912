import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ReviewsRoundedIcon from "@mui/icons-material/ReviewsRounded";
import icon_upgrade from "../../../res/images/icons/icon-upgrade.svg";
import { Box, Button } from "@mui/material";
import STypography from "../../../components/SMui/STypography";
import { Trans } from "react-i18next";
import Link from "../../../components/Link";
import { ROUTES } from "../../../app/routing";
import IntercomHelper from "../../../app/helpers/IntercomHelper";
import UserHelper from "../../../app/helpers/UserHelper";

export const DatasourceWarning = ({
  totalReviews,
  maxReviews,
  isEnterprise = false,
  status = "success",
  service = null,
  ...props
}) => {
  const isError = status === "error";

  if (status == "success") {
    return <></>;
  }

  let exceedPercent = ((totalReviews - maxReviews) / maxReviews) * 100;
  exceedPercent = exceedPercent?.toFixed();

  const isAdmin = UserHelper.isAdmin();

  let captionDescription = "";
  if (service == "Youtube") {
    captionDescription = "Sensia treat each caption line as a review. ";
  }

  let warningMessage = (
    <Trans i18nKey="hit_storage_media_limit_warning_youtube">
      Caution! Adding this source will push you{" "}
      <span className="font-weight-600">{{ exceedPercent }}%</span> over your
      limit of {{ maxReviews }} reviews. {captionDescription}You can still
      proceed, but future updates will be paused.
    </Trans>
  );

  let errorMessage = (
    <Trans i18nKey="hit_storage_media_limit_error_admin">
      Oops! Adding this source will push you{" "}
      <span className="font-weight-600">{{ exceedPercent }}%</span> over your
      limit of {{ maxReviews }} reviews. {captionDescription}You can't add more
      data yet. Keep things smooth,{" "}
      <STypography
        onClick={() =>
          props.history.push(ROUTES.SETTINGS_PLAN_AND_BILLING_OVERVIEW)
        }
        sx={{
          display: "inline",
          fontWeight: 400,
          textTransform: "none",
          color: "#3A79E6",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Trans i18nKey="unlock_more_reviews">Unlock More Reviews Now</Trans>
      </STypography>
    </Trans>
  );

  if (isEnterprise && isAdmin) {
    errorMessage = (
      <Trans i18nKey="hit_storage_media_limit_error_admin">
        Oops! Adding this source will push you{" "}
        <span className="font-weight-600">{{ exceedPercent }}%</span> over your
        limit of {{ maxReviews }} reviews. {captionDescription}You can't add
        more data yet. Need more reviews?{" "}
        <STypography
          onClick={() => IntercomHelper.trackEvent("contact-success-team")}
          sx={{
            display: "inline",
            fontWeight: 400,
            textTransform: "none",
            color: "#3A79E6",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Trans i18nKey="talk_to_our_success_team">
            Talk to our success team
          </Trans>
        </STypography>
      </Trans>
    );
  } else if (!isAdmin) {
    errorMessage = (
      <Trans i18nKey="hit_storage_media_limit_error_admin">
        Oops! Adding this source will push you{" "}
        <span className="font-weight-600">{{ exceedPercent }}%</span> over your
        limit of {{ maxReviews }} reviews. {captionDescription}You can't add
        more data yet. Free up space to continue exploring
      </Trans>
    );
  }

  let bgColor = "#EAF8FD"; // "#FDE7D4"
  let icon = <ErrorOutlineOutlinedIcon sx={{ color: "#3A79E6" }} />;
  let message = warningMessage;

  if (isError) {
    bgColor = "#FDE7D4";
    icon = <ReviewsRoundedIcon sx={{ color: "#FF9378" }} />;
    message = errorMessage;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        p: "14px 16px",
        borderRadius: "4px",
        border: "1px solid #e2ebfb4d",
        backgroundColor: bgColor,
      }}
    >
      {icon}
      <STypography sx={{ fontWeight: 400 }}>{message}</STypography>
    </Box>
  );
};
