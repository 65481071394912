import * as React from "react";

import cartouche from "../../res/images/cartouches/cartouche.png";
import { Card, Box, Typography, Divider } from "@mui/material";
import { Button, Link } from "react-admin";

const CardWithIcon = (props) => {
  const { icon, title, subtitle, to, children } = props;

  return (
    <Link style={{ width: "100%", margin: 0, textDecoration: "none" }} to={to}>
      <Button
        sx={{
          width: "100%",
          "& .MuiButton-startIcon": { width: "100%", m: 0 },
        }}
      >
        {/* <Link style={{ textDecoration: "none" }} to={to}> */}

        <Card href={to} sx={{ height: "85px", width: "100%" }}>
          <Box
            sx={{
              overflow: "inherit",
              padding: "16px",
              background: `url(${cartouche}) no-repeat`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box width="3em" className="icon">
              {icon}
            </Box>
            <Box textAlign="right">
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1",
                }}
                color="textSecondary"
              >
                {title}
              </Typography>
              <Typography variant="h5" component="h2">
                {subtitle === null && "N/A"}
                {subtitle !== undefined && subtitle}
              </Typography>
            </Box>
          </Box>
          {children && <Divider />}
          {children}
        </Card>
      </Button>
    </Link>
  );
};

export default CardWithIcon;
