import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  required,
  Create,
  Edit,
  useNotify,
  useRedirect,
  SaveButton,
  DeleteButton,
  BooleanInput,
  NumberInput,
  Toolbar,
  useGetList,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import PromptInputText from "./elements/PromptInputText";
import { BackButton } from "./elements/CustomFields";
import { Box, Typography } from "@mui/material";

export const SectionList = () => {
  return (
    <List resource="sections" filter={{ reportId: "reportid" }}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="year" />
      </Datagrid>
    </List>
  );
};

export const getUrlParameters = () => {
  const href = window.location.href;
  const params = href.split("?")[1];

  // Be sure url params exist
  if (params && params !== "") {
    const result = params.split("&").reduce(function (res, item) {
      const parts = item.split("=");
      res[parts[0]] = parts[1];
      return res;
    }, {});

    return result;
  }

  return {};
};

export const SectionCreate = () => {
  const urlParams = getUrlParameters();
  const reportId = urlParams["reportId"];
  const choices = [
    { id: "FIRST_REVIEW", name: "First Review" },
    { id: "GENERATION_DATE", name: "Generation Date" },
  ];
  const scope_choices = [
    { id: "EXTENDED", name: "Extended" },
    { id: "STRICT", name: "Strict" },
  ];

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Section successfully created`);
    redirect(`/reports/${reportId}/1`);
  };

  const { data, isLoading } = useGetList("feature-list", {
    id: reportId,
  });

  return (
    <Create
      resource="sections"
      mutationOptions={{ onSuccess, meta: { reportId: reportId } }}
    >
      <BackButton to={`/reports/${reportId}/1`} />
      <SimpleForm toolbar={<SectionEditToolbar />}>
        <Typography variant="h6" gutterBottom>
          General Information
        </Typography>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput
            sx={{ flex: 1 }}
            source="title"
            label="Title"
            validate={[required()]}
          />
          <TextInput
            sx={{ flex: 1 }}
            source="max_tokens"
            label="Max Tokens"
            type="number"
            validate={[required()]}
          />
        </Box>
        <NumberInput source="temperature" label="Temperature" min="0" max="2" step={0.1} validate={[required()]} helperText="Value between 0-2" defaultValue={0.7}/>
        <BooleanInput label="Display in toc" source="display_in_toc" />
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput
            sx={{ flex: 1 }}
            source="display_order"
            label="Display order"
            type="number"
            validate={[required()]}
          />
          <ReferenceInput
            label="Slide Layout"
            source="slide_id"
            reference="slides"
          >
            <SelectInput
              sx={{ flex: 1 }}
              optionText="title"
              helperText="Unselected sections won't export to PPT"
            />
          </ReferenceInput>
        </Box>
        <Typography variant="h6" gutterBottom>
          Review Filters
        </Typography>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput sx={{ flex: 1 }} source="classes" label="Class" />
          <SelectInput
            sx={{ flex: 1 }}
            source="review_scope"
            defaultValue="EXTENDED"
            choices={scope_choices}
            validate={[required()]}
          />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <SelectInput
            sx={{ flex: 1 }}
            source="day_reference"
            choices={choices}
            helperText="Choose the reference for the filtering date"
          />
          <TextInput
            sx={{ flex: 1 }}
            source="day_delta"
            label="Day"
            type="number"
          />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput
            sx={{ flex: 1 }}
            source="min_score"
            label="Min Score"
            type="number"
            inputProps={{
              maxLength: 13,
              step: "1",
            }}
            parse={(value) => {
              if (value) {
                return parseFloat(value);
              } else {
                return null;
              }
            }}
          />
          <TextInput
            sx={{ flex: 1 }}
            source="max_score"
            label="Max Score"
            type="number"
            inputProps={{
              maxLength: 13,
              step: "1",
            }}
            parse={(value) => {
              if (value) {
                return parseFloat(value);
              } else {
                return null;
              }
            }}
          />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <BooleanInput label="Verified Reviews" source="verified_reviews" />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput
            sx={{ flex: 1 }}
            source="number_of_reviews"
            label="Number of Reviews to Use"
            type="number"
            helperText="Number of reviews to be used to generate the section"
            validate={[required()]}
          />
          <TextInput
            sx={{ flex: 1 }}
            source="minimum_number_of_reviews"
            label="Minimum Number of Reviews to use"
            type="number"
          />
        </Box>

        <Typography variant="h6" gutterBottom>
          Content Generation
        </Typography>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <BooleanInput label="Generate image" source="generate_img" />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.generate_img && (
                <TextInput
                  label="Prompt Dall-E"
                  source="dalle_prompt"
                  fullWidth="True"
                  resettable
                  multiline
                  helperText="Leave empty to use default (ex: Based on the brief below, create a DALL-E prompt.)"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <BooleanInput
            label="Generate variables"
            source="generate_variables"
          />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.generate_variables && (
                <TextInput
                  label="Prompt Variables"
                  source="json_prompt"
                  fullWidth="True"
                  resettable
                  multiline
                  helperText="Leave empty to use default (Generate for me a JSON object while retaining the HTML style and keeping it at a single depth level, based on the following content. )"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "block", width: "100%" }}
        >
          <PromptInputText
            source="prompt"
            label="Prompt"
            isRequired
            variables={data}
            isLoading={isLoading}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

const SectionEditToolbar = () => {
  const urlParams = getUrlParameters();
  const reportId = urlParams["reportId"];

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Section successfully deleted`);
    redirect(`/reports/${reportId}/1`);
  };
  return (
    <Toolbar sx={{ justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        mutationOptions={{ onSuccess, meta: { reportId: reportId } }}
      />
    </Toolbar>
  );
};

export const SectionEdit = () => {
  const urlParams = getUrlParameters();
  const reportId = urlParams["reportId"];
  const choices = [
    { id: "FIRST_REVIEW", name: "First Review" },
    { id: "GENERATION_DATE", name: "Generation Date" },
  ];
  const scope_choices = [
    { id: "EXTENDED", name: "Extended" },
    { id: "STRICT", name: "Strict" },
  ];

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Section successfully updated`);
    redirect(`/reports/${reportId}/1`);
  };

  const { data, isLoading } = useGetList("feature-list", {
    id: reportId,
  });

  return (
    <Edit
      resource="sections"
      mutationOptions={{ onSuccess, meta: { reportId: reportId } }}
      mutationMode="pessimistic"
    >
      <BackButton to={`/reports/${reportId}/1`} />
      <SimpleForm toolbar={<SectionEditToolbar />}>
        <Typography variant="h6" gutterBottom>
          General Information
        </Typography>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput
            sx={{ flex: 1 }}
            source="title"
            label="Title"
            validate={[required()]}
          />
          <TextInput
            sx={{ flex: 1 }}
            source="max_tokens"
            label="Max Tokens"
            type="number"
            validate={[required()]}
          />
        </Box>
        <NumberInput source="temperature" label="Temperature" min="0" max="2"  step={0.1} validate={[required()]}  helperText="Value between 0-2" />
        <BooleanInput label="Display in toc" source="display_in_toc" />
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput
            sx={{ flex: 1 }}
            source="display_order"
            label="Display order"
            type="number"
            validate={[required()]}
          />
          <ReferenceInput
            label="Slide Layout"
            source="slide_id"
            reference="slides"
          >
            <SelectInput
              sx={{ flex: 1 }}
              optionText="title"
              helperText="Unselected sections won't export to PPT"
            />
          </ReferenceInput>
        </Box>
        <Typography variant="h6" gutterBottom>
          Review Filters
        </Typography>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput sx={{ flex: 1 }} source="classes" label="Class" />
          <SelectInput
            sx={{ flex: 1 }}
            source="review_scope"
            choices={scope_choices}
            validate={[required()]}
          />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <SelectInput
            sx={{ flex: 1 }}
            source="day_reference"
            choices={choices}
            helperText="Choose the reference for the filtering date"
          />
          <TextInput
            sx={{ flex: 1 }}
            source="day_delta"
            label="Day"
            type="number"
          />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput
            sx={{ flex: 1 }}
            source="min_score"
            label="Min Score"
            type="number"
            inputProps={{
              maxLength: 13,
              step: "1",
            }}
            parse={(value) => {
              if (value) {
                return parseFloat(value);
              } else {
                return null;
              }
            }}
          />
          <TextInput
            sx={{ flex: 1 }}
            source="max_score"
            label="Max Score"
            type="number"
            inputProps={{
              maxLength: 13,
              step: "1",
            }}
            parse={(value) => {
              if (value) {
                return parseFloat(value);
              } else {
                return null;
              }
            }}
          />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <BooleanInput label="Verified Reviews" source="verified_reviews" />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <TextInput
            sx={{ flex: 1 }}
            source="number_of_reviews"
            label="Number of Reviews to Use"
            type="number"
            helperText="Number of reviews to be used to generate the section"
            validate={[required()]}
          />
          <TextInput
            sx={{ flex: 1 }}
            source="minimum_number_of_reviews"
            label="Minimum Number of Reviews to use"
            type="number"
          />
        </Box>

        <Typography variant="h6" gutterBottom>
          Content Generation
        </Typography>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <BooleanInput label="Generate image" source="generate_img" />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.generate_img && (
                <TextInput
                  label="Prompt Dall-E"
                  source="dalle_prompt"
                  fullWidth="True"
                  resettable
                  multiline
                  helperText="Leave empty to use default (ex: Based on the brief below, create a DALL-E prompt.)"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <BooleanInput
            label="Generate variables"
            source="generate_variables"
          />
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "flex", width: "100%" }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.generate_variables && (
                <TextInput
                  label="Prompt Variables"
                  source="json_prompt"
                  fullWidth="True"
                  resettable
                  multiline
                  helperText="Leave empty to use default (Generate for me a JSON object while retaining the HTML style and keeping it at a single depth level, based on the following content. )"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
        </Box>
        <Box
          gap={2}
          maxWidth={"800px"}
          display={{ xs: "block", sm: "block", width: "100%" }}
        >
          <PromptInputText
            source="prompt"
            label="Prompt"
            isRequired
            variables={data}
            isLoading={isLoading}
          />
        </Box>
      </SimpleForm>
    </Edit>
  );
};
