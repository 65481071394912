import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import {
  ACCOUNT_PLAN,
  getAccountPlan,
  globalConstants,
  PLAN_JSON_LOGIC_KEYS,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";
import Header from "../../components/header/Header";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Footer from "../../components/footer/Footer";
import ClassListItem from "../../components/classListItem/ClassListItem";
import Link from "../../components/Link";
import { classService } from "../../app/services/class.service";
import { projectService } from "../../app/services/project.service";
import Loader from "../../components/loader/Loader";
import { CLASS_ADD, PROJECT_DETAIL, PROJECT_EDIT } from "../../app/routing";
import CollectionHelper from "../../app/helpers/CollectionHelper";
import DeleteModal from "../../components/modal/DeleteModal";
import HttpHelper from "../../app/helpers/HttpHelper";
import ReportHelper from "../../app/helpers/ReportHelper";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import ButtonField from "../../components/form/field/ButtonField";
import UpgradePlanToAddClassModal from "./elements/UpgradePlanToAddClassModal";

class ManageClassesView extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const modale = urlParams.modale || null;
    const displayUpgradeAddClassModale = modale === "upgrade-plan";
    this.state = {
      is_loaded: false,
      inline_loading: false,
      formData: {
        name: "",
        classe_ids: [],
      },
      project: null,
      value: "",
      submitLoading: false,
      classesList: [],
      otherClassesList: [],
      canEditIntegratedClass: false,
      canEditCustomClass: false,
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      currentPlan: ACCOUNT_PLAN.FREE,
      maxNbClasses: 0,
      displayUpgradeAddClassModale: displayUpgradeAddClassModale,
      loadingAddClassButton: false,
    };
    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.getClassesList = this.getClassesList.bind(this);
    this.getOtherClassesList = this.getOtherClassesList.bind(this);

    this.onOpenDeleteModal = this.onOpenDeleteModal.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.onClickAddClassButton = this.onClickAddClassButton.bind(this);
    this.onCloseUpgradePlanToAddClassModal =
      this.onCloseUpgradePlanToAddClassModal.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.CUSTOMIZE_INTEGRATED_CLASS,
      {},
      (response) => {
        this.setState({
          canEditIntegratedClass: response.status === "success",
          canEditCustomClass: response.status === "success",
        });
        this.fetchProject();
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
        this.fetchProject();
      }
    );
  }

  fetchData() {
    let project_id = this.props.match.params.id;
    classService.get.getProjectCategories(
      this.state.project.accountId,
      project_id,
      (response) => {
        this.setState({
          is_loaded: true,
          classesList: this.getClassesList(response.data),
          otherClassesList: this.getOtherClassesList(response.data),
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({ is_loaded: true });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  getClassesList(allClasses) {
    let ret = [];
    allClasses?.forEach((element) => {
      if (!ReportHelper.classIsExcludedWords(element)) {
        ret.push(element);
      }
    });
    return ret;
  }

  getOtherClassesList(allClasses) {
    let ret = [];
    allClasses.forEach((element) => {
      if (ReportHelper.classIsExcludedWords(element)) {
        ret.push(element);
      }
    });
    return ret;
  }

  fetchProject() {
    let project_id = this.props.match.params.id;
    projectService.get.get(
      project_id,
      (response) => {
        let newFormData = Object.assign({}, this.state.formData);
        newFormData.classe_ids = response.classe_ids;
        newFormData.name = response.name;
        this.setState(
          {
            project: response,
            formData: newFormData,
          },
          () => {
            this.fetchData();
          }
        );
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "report" }
    );
  }

  handleChange(e) {
    let newFormData = Object.assign({}, this.state.formData);
    const isChecked = e.target.checked;
    const value = parseInt(e.target.value);
    if (isChecked) {
      newFormData.classe_ids.push(value);
    } else {
      const index = newFormData.classe_ids.indexOf(value);
      if (index > -1) {
        newFormData.classe_ids.splice(index, 1);
      }
    }
    this.setState({
      formData: newFormData,
    });
  }

  onSubmitEdit = (e) => {
    e.preventDefault();
    let formData = Object.assign({}, this.state.formData);
    if (formData) {
      this.setState({ submitLoading: true });
      projectService.put.update(
        this.state.project.id,
        formData,
        (response) => {
          setTimeout(() => {
            this.props.addToast("Classes successfully updated", {
              type: "success",
              autoDismiss: true,
            });
          }, 800);
          this.props.history.push(PROJECT_DETAIL(this.state.project.id));
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.setState({ submitLoading: false });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    }
  };

  onCloseDeleteModal() {
    this.setState({
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onOpenDeleteModal(itemId) {
    const item = CollectionHelper.findOneById(this.state.classesList, itemId);
    if (item) {
      this.setState({
        delete: {
          display: true,
          item: item,
          loading: false,
          error: null,
        },
      });
    }
  }

  onDeleteItem() {
    let deleteData = Object.assign({}, this.state.delete);
    if (deleteData.item) {
      deleteData.loading = true;
      this.setState({
        delete: deleteData,
      });
      classService.delete.delete(
        deleteData.item.id,
        (response) => {
          this.props.addToast(
            'Class "' + this.state.delete.item.title + '" successfully deleted',
            { type: "success", autoDismiss: true }
          );
          const newListItems = CollectionHelper.removeOneById(
            this.state.classesList,
            deleteData.item.id
          );
          const newTotalItem = this.state.totalItems - 1;
          this.setState({
            delete: {
              display: false,
              item: null,
              loading: false,
              error: null,
            },
            classesList: newListItems,
            totalItems: newTotalItem,
          });
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          deleteData.loading = false;
          deleteData.error = error;
          this.setState({
            delete: deleteData,
          });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    }
  }

  onCloseUpgradePlanToAddClassModal() {
    this.setState({
      displayUpgradeAddClassModale: false,
    });
    this.props.history.replace({
      pathname: PROJECT_EDIT(this.state.project.id),
    });
  }

  onClickAddClassButton() {
    this.setState({ loadingAddClassButton: true });
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.CREATE_CUSTOM_CLASS,
      {},
      (response) => {
        const displayUpgradeAddClassModale = response.status === "error";
        this.setState({
          maxNbClasses: response.data.nb_custom_classes,
          currentPlan: getAccountPlan(response.data.plan),
          displayUpgradeAddClassModale: displayUpgradeAddClassModale,
          loadingAddClassButton: false,
        });
        if (response.status === "success") {
          this.props.history.push(CLASS_ADD(this.state.project.id));
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          loadingAddClassButton: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Manage classes")} - ${
      globalConstants.WEBSITE_TITLE
    }`;
    const project = this.state.project;
    if (this.state.is_loaded) {
      return (
        <Fragment>
          <Header isProject={true} />
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <div className="padding-top-60 padding-bottom-20 responsive-920-padding-top-20">
            <div className="central-content">
              {project && (
                <div className="padding-bottom-5">
                  <Link
                    to={PROJECT_DETAIL(project.id)}
                    className="font-size-18 link-grey transition-color text-break-line"
                  >
                    {project.name}
                  </Link>
                </div>
              )}
              <ButtonField
                className="button blue float-right"
                loading={this.state.loadingAddClassButton}
                disabled={
                  !this.state.canEditCustomClass &&
                  !this.state.canEditIntegratedClass
                }
                onClick={this.onClickAddClassButton}
              >
                {t("Add class")}
              </ButtonField>
              <div className="font-size-36 font-inter-700 color-000 padding-bottom-40">
                {t("Manage classes")}
              </div>
              <div className="font-size-24 font-inter-600 color-000 padding-bottom-5 text-break-line">
                {t("Select classes for ")}
                {project.name}
              </div>
              <div className="font-size-15 color-7f7f7f font-inter-400 padding-bottom-36">
                {t(
                  "Select the attribute classes you want to be analyzed within your project"
                )}
              </div>
              <form>
                <div className="padding-bottom-36">
                  {this.state.classesList &&
                    this.state.classesList.map((item, i) => {
                      return (
                        <ClassListItem
                          key={`classListItem${i}`}
                          classItem={item}
                          name="classe_ids"
                          hideDeleteAction={
                            !this.state.canEditCustomClass &&
                            !this.state.canEditIntegratedClass
                          }
                          projectId={this.state.project.id}
                          disabled={
                            this.state.submitLoading ||
                            (!this.state.canEditCustomClass &&
                              !this.state.canEditIntegratedClass)
                          }
                          canEditIntegratedClass={
                            this.state.canEditIntegratedClass
                          }
                          canEditCustomClass={this.state.canEditCustomClass}
                          displayButtonEdit={true}
                          onChange={this.handleChange}
                          onOpenDeleteModal={() =>
                            this.onOpenDeleteModal(item.id)
                          }
                          checked={this.state.formData.classe_ids.includes(
                            item.id
                          )}
                        />
                      );
                    })}
                </div>
                <div className="overflow-hidden">
                  {!this.state.submitLoading && (
                    <div className="float-right">
                      <button
                        type="button"
                        className="button backgroundless"
                        onClick={() =>
                          this.props.history.push(
                            PROJECT_DETAIL(this.state.project.id)
                          )
                        }
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        disabled={
                          !this.state.canEditCustomClass &&
                          !this.state.canEditIntegratedClass
                        }
                        onClick={this.onSubmitEdit}
                        className="margin-left-5 button backgroundless"
                      >
                        {t("Validate")}
                      </button>
                    </div>
                  )}
                  {this.state.submitLoading && (
                    <div className="float-right" style={{ padding: "2px 0" }}>
                      <Loader />
                    </div>
                  )}
                </div>
              </form>
              <div className="font-size-24 font-inter-600 color-000 padding-top-30 padding-bottom-5">
                {t("Manage excluded words")}
              </div>
              <div className="font-size-15 color-7f7f7f font-inter-400 padding-bottom-36">
                {t(
                  "Customize the irrelevant words you want to exclude from the analysis."
                )}
              </div>
              <div className="padding-bottom-36">
                {this.state.otherClassesList &&
                  this.state.otherClassesList.map((item, i) => {
                    return (
                      <ClassListItem
                        key={`otherClassListItem${i}`}
                        classItem={item}
                        name=""
                        isOther={true}
                        projectId={this.state.project.id}
                        disabled={
                          this.state.submitLoading ||
                          (!this.state.canEditCustomClass &&
                            !this.state.canEditIntegratedClass)
                        }
                        hideDeleteAction={
                          !this.state.canEditCustomClass &&
                          !this.state.canEditIntegratedClass
                        }
                        canEditIntegratedClass={
                          this.state.canEditIntegratedClass
                        }
                        canEditCustomClass={this.state.canEditCustomClass}
                        displayButtonEdit={true}
                        onChange={this.handleChange}
                        onOpenDeleteModal={() =>
                          this.onOpenDeleteModal(item.id)
                        }
                        checked={false}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <Footer />
          {this.state.delete.display && (
            <DeleteModal
              itemValue={
                this.state.delete.item ? this.state.delete.item.title : null
              }
              title={t("Delete class")}
              subtitle={t("You are going to delete the class")}
              inputLabel={t("Enter class name to confirm")}
              buttonLabel={t("Delete class")}
              onClose={this.onCloseDeleteModal}
              loading={this.state.delete.loading}
              onDeleteItem={this.onDeleteItem}
            />
          )}
          {this.state.displayUpgradeAddClassModale && (
            <UpgradePlanToAddClassModal
              onClose={this.onCloseUpgradePlanToAddClassModal}
              maxNbClasses={this.state.maxNbClasses}
              currentPlan={this.state.currentPlan}
            />
          )}
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ManageClassesView));
