import React from "react";
import { withTranslation } from "react-i18next";
import {
  ACCOUNT_PLAN,
  ACCOUNT_PLAN_FREQUENCY,
  globalConstants,
} from "../../app/constants";
import HttpHelper from "../../app/helpers/HttpHelper";
import StringHelper from "../../app/helpers/StringHelper";
import { PLAN_UGRAPDE } from "../../app/routing";

import Check from "../../res/images/icons/check.svg";
import Link from "../Link";
import "./pricingBox.css";
import { billingService } from "../../app/services/billing.service";
import Loader from "../loader/Loader";
import { Typography } from "@mui/material";
import STypography from "../SMui/STypography";
import CheckIcon from "@mui/icons-material/Check";

function PricingBox(props) {
  const {
    t,
    planType,
    title,
    subtitle,
    subprice,
    subpriceYearly,
    freeCredit,
    advantages,
    recommended,
    yearly,
    currentPlan,
    rawClass,
    monthlyPrice,
    yearlyPrice,
    currency_code,
    handleUpgrade,
  } = props;
  const classes = ["content-text", "content-pricing-box"];

  if (recommended) {
    classes.push("recommended");
  }

  let displayPrice = "";
  let displaySubPrice = "";

  const language = navigator.language;

  const onClickContactSales = () => {
    window.open(globalConstants.SALES_URL, "_blank", "noreferrer");
  };

  let button = <></>;

  if (currentPlan === planType) {
    button = (
      <div className="btn-position">
        <div className="plan-button cursor-default-important">
          {t("Current plan")}
        </div>
      </div>
    );
  } else if (planType === ACCOUNT_PLAN.ENTERPRISE) {
    button = (
      <div className="btn-position">
        <button className="button upgrade" onClick={onClickContactSales}>
          {t("Contact sales")}
        </button>
      </div>
    );
  } else {
    button = (
      <div className="btn-position">
        <button className="button upgrade" onClick={() => handleUpgrade()}>
          {t("Upgrade now")}
        </button>
      </div>
    );
  }

  if (planType === ACCOUNT_PLAN.ENTERPRISE) {
    displayPrice = (
      <div className="price">
        <span className="maj">{t("Custom plan")}</span>
      </div>
    );
    displaySubPrice = <div className="sub-price">{subprice}</div>;
  } else if (planType === ACCOUNT_PLAN.FREE) {
    displaySubPrice = <div className="sub-price only-text">{subprice}</div>;
    displayPrice = (
      <div className="price">
        <span className="maj">0/</span>
        <span className="min">{t("forever")}</span>
      </div>
    );
  } else if (yearly) {
    displayPrice = (
      <div className="price">
        <span className="maj">
          {new Intl.NumberFormat(language, {
            style: "currency",
            currency: currency_code,
            minimumFractionDigits: 0,
          }).format(yearlyPrice.toFixed())}
          /
        </span>
        <span className="min">{t("User/Year")}</span>
      </div>
    );
    displaySubPrice = (
      <div className="sub-price">
        {new Intl.NumberFormat(language, {
          style: "currency",
          currency: currency_code,
          minimumFractionDigits: 0,
        }).format(monthlyPrice.toFixed())}
        {subprice}
      </div>
    );
  } else {
    displayPrice = (
      <div className="price">
        <span className="maj">
          {new Intl.NumberFormat(language, {
            style: "currency",
            currency: currency_code,
            minimumFractionDigits: 0,
          }).format(monthlyPrice.toFixed())}
          /
        </span>
        <span className="min">{t("User/Month")}</span>
      </div>
    );
    displaySubPrice = (
      <div className="sub-price">
        {new Intl.NumberFormat(language, {
          style: "currency",
          currency: currency_code,
          minimumFractionDigits: 0,
        }).format(yearlyPrice.toFixed())}
        {subpriceYearly}
      </div>
    );
  }

  const displayInfo = (
    <STypography
      sx={{
        fontWeight: 400,
        mb: "45px",
      }}
    >
      <span style={{ fontWeight: 600 }}>{freeCredit} free credit</span> included
      on a yearly subscription
    </STypography>
  );

  return (
    <>
      <div className={rawClass.join(" ") + " parent-content-pricing-box"}>
        <div className={classes.join(" ")}>
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
          {displayPrice}
          {displaySubPrice}
          {/* {displayInfo} */}
          <div className="content-advantages margin-top-30">
            {advantages.map((element, y) => {
              return (
                <div
                  key={element.title + y}
                  className="advantages-box padding-bottom-15"
                >
                  <div className="title">{element.title}:</div>
                  {element.advantage.map((adv, i) => {
                    return (
                      <div key={adv + i} className="raw-advantage">
                        <CheckIcon
                          sx={{
                            height: "20px",
                            width: "20px",
                            color: "#27C986",
                          }}
                        />
                        <div className="advantage-lst">{adv}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {button}
        </div>
      </div>
    </>
  );
}

PricingBox.defaultProps = {
  recommended: false,
};

export default withTranslation()(PricingBox);
