import React from "react";
import { Box, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LogoSensia from "../../res/images/logo/logo-sensia-modal.svg";
import { useTranslation } from "react-i18next";
import STypography from "../../components/SMui/STypography";
import HttpHelper from "../../app/helpers/HttpHelper";

const SubscriptionSuccessView = (props) => {
  const { t } = useTranslation();
  const urlParameters = HttpHelper.getUrlParameters(props.location.search);
  const isCancel = urlParameters.cancel;
  return (
    <Box
      style={{
        textAlign: "center",
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <img
          src={LogoSensia}
          alt="Sensia Logo"
          style={{ width: "126px", marginTop: "30px" }}
        />
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {/* Icon and Success Message */}
        <CheckCircleIcon style={{ color: "#27C986", fontSize: "40px" }} />
        <STypography
          sx={{
            mt: "10px",
            fontSize: "30px",
            fontWeight: 700,
          }}
        >
          {t("Success")}
        </STypography>
        <STypography
          sx={{
            mt: "20px",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "21px",
          }}
        >
          {isCancel
            ? t("Your subscription has been successfully cancelled.")
            : t("Your subscription has been successfully updated.")}
        </STypography>

        {/* Back to Home Button */}
        <Button
          variant="contained"
          color="primary"
          href="/"
          size="large"
          style={{
            marginTop: "30px",
            textTransform: "none",
            borderRadius: "8px",
          }}
        >
          {t("Back to home")}
        </Button>
      </Box>
    </Box>
  );
};

export default SubscriptionSuccessView;
