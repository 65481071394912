import React from "react";
import { withTranslation } from "react-i18next";
import imgEmptyState from "../../res/images/empty-state-insight.svg";
import "./EmptyState.css";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import { PLAN_JSON_LOGIC_KEYS } from "../../app/constants";
import { Typography } from "@mui/material";

class EmptyState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayEnterpriseLink: false,
    };
  }

  componentDidMount() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
      {},
      (response) => {
        const enterpriseFeatureIsDisabled = response.status === "error";
        this.setState({
          displayEnterpriseLink: !enterpriseFeatureIsDisabled,
        });
      },
      (error) => {}
    );
  }
  render() {
    const { t } = this.props;
    return (
      <div className="central-content" style={{ paddingTop: "78px" }}>
        <div className="empty-state-content display-flex justify-content-space-between responsive-emptyState">
          <div id="EmptyState-box">
            <div className="font-inter-700 font-size-24 padding-bottom-12 color-616161 text-align-left">
              {t("Unleash the full potential of")} <br />{" "}
              {t("your project with Insights")}
            </div>
            <div className="font-inter-400 font-size-15 padding-bottom-36 color-757575 text-align-left margin-top-32 line-height-1-5">
              {t(
                "Insights is where Sensia works its magic, providing you with an Summary of your project, highlighting key areas of interest, and delivering personalized recommendations to help you improve. But we need your data to make it happen. Connect your data sources and let Sensia show you the true potential of your project."
              )}
            </div>
            <div style={{ textAlign: "center" }}>
              <button
                onClick={this.props.handleClickAddDatasouce}
                className="button blue"
              >
                {t("Add data source")}
              </button>
            </div>
            {this.state.displayEnterpriseLink && (
              <div className="font-inter-400 font-size-15 color-000 text-align-left margin-top-32 line-height-1-5 display-flex align-items-center gap-7">
                {t("Don’t have link or file to import ?")}

                <Typography
                  onClick={() => this.props.handleClickSearchDatasouce()}
                  sx={{
                    color: "#306ED6",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    cursor: "pointer",
                  }}
                >
                  {t("Search for it")}
                </Typography>
              </div>
            )}
          </div>
          <div style={{ width: "50%" }}>
            <img alt="" src={imgEmptyState} className="img-100" />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EmptyState);
