import HttpHelper from "../helpers/HttpHelper";
import { saveAs } from "file-saver";
import axios from "axios";

async function getProjectMedias(
  projectId,
  formData,
  onSuccess,
  onError,
  signal = null
) {
  const url = "/api/v1/projects/" + projectId + "/medias";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError,
    signal
  );
}

async function searchDatasource(formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/scrape/search",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function createProjectMedia(projectId, formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/projects/" + projectId + "/medias",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function createMultipleProjectDatasource(
  projectId,
  formData,
  onSuccess,
  onError
) {
  return HttpHelper.authApiFetch(
    "/api/v1/projects/" + projectId + "/add-multiple-source",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function deleteProjectMedia(projectId, datasourceId, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId,
    HttpHelper.method.DELETE,
    null,
    onSuccess,
    onError
  );
}

async function updateProjectMedia(
  projectId,
  datasourceId,
  formData,
  onSuccess,
  onError
) {
  const url = "/api/v1/projects/" + projectId + "/medias/" + datasourceId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.PUT,
    formData,
    onSuccess,
    onError
  );
}

async function reloadProjectMedia(projectId, datasourceId, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId + "/reload",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function analyseProjectMedia(
  projectId,
  datasourceId,
  onSuccess,
  onError
) {
  return HttpHelper.authApiFetch(
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId + "/analyse",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getMedia(projectId, datasourceId, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function checkMediaStatus(projectId, datasourceId, onSuccess, onError) {
  const url =
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId + "/check";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getMediaJson(projectId, datasourceId, onSuccess, onError) {
  const url =
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId + "/json";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getMediaCsv(
  projectId,
  datasourceId,
  filename,
  onSuccess,
  onError
) {
  const requestOptions = {
    method: HttpHelper.method.GET,
    headers: HttpHelper.authHeader({ "Content-Type": "text/csv" }),
    responseType: "blob",
  };
  const url = HttpHelper.getApiUrl(
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId + "/csv"
  );
  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedVanillaResponse)
    .then((response) => response.blob())
    .then(
      (blob) => {
        saveAs(blob, filename);
        HttpHelper.handleFunction(onSuccess);
        return blob;
      },
      (error) => {
        HttpHelper.handleFunction(onError, error);
      }
    )
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
}

async function getMediaXlsx(
  projectId,
  datasourceId,
  filename,
  onSuccess,
  onError
) {
  const requestOptions = {
    method: HttpHelper.method.GET,
    headers: HttpHelper.authHeader({ "Content-Type": "text/csv" }),
    responseType: "blob",
  };
  const url = HttpHelper.getApiUrl(
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId + "/xlsx"
  );
  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedVanillaResponse)
    .then((response) => response.blob())
    .then(
      (blob) => {
        saveAs(blob, filename);
        HttpHelper.handleFunction(onSuccess);
        return blob;
      },
      (error) => {
        HttpHelper.handleFunction(onError, error);
      }
    )
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
}

async function updateMediaCaptions(
  projectId,
  datasourceId,
  formData,
  onSuccess,
  onError
) {
  const url =
    "/api/v1/projects/" + projectId + "/medias/" + datasourceId + "/captions";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.PUT,
    formData,
    onSuccess,
    onError
  );
}

async function getRestoreCaptions(projectId, datasourceId, onSuccess, onError) {
  const url =
    "/api/v1/projects/" +
    projectId +
    "/medias/" +
    datasourceId +
    "/restore_captions";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getMediaReport(
  projectId,
  datasourceId,
  selected_keywords,
  onSuccess,
  onError
) {
  const formData = {
    selected_keywords: selected_keywords,
  };
  const url =
    "/api/v1/projects/" +
    projectId +
    "/medias/" +
    datasourceId +
    "/report-list";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function getKeywordReport(
  projectId,
  datasourceId,
  word,
  onSuccess,
  onError
) {
  const url =
    "/api/v1/projects/" +
    projectId +
    "/medias/" +
    datasourceId +
    "/report/" +
    word;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getScoreEmotionGlobal(
  projectId,
  formData,
  onSuccess,
  onError,
  signal = null
) {
  const url = "/api/v1/projects/global-data-report/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError,
    signal
  );
}

async function getInformationUrl(
  webUrl,
  productSourceId,
  project_id,
  onSuccess,
  onError
) {
  const formData = {
    url: webUrl,
    product_source_id: productSourceId,
    project_id: project_id,
  };
  const url = "/api/v1/url/check";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function uploadTxtFile(formData, onUploadProgress, onSuccess, onError) {
  return axios
    .request({
      method: HttpHelper.method.POST,
      url: HttpHelper.getApiUrl("/api/v1/media/txt/upload"),
      headers: HttpHelper.authHeader(),
      data: formData,
      onUploadProgress: (p) => {
        let progress = Math.round((p.loaded / p.total) * 100);
        if (progress >= 100) {
          progress = 99;
        }
        HttpHelper.handleFunction(onUploadProgress, progress);
      },
    })
    .then(
      (response) => {
        const { data } = response;
        HttpHelper.handleFunction(onSuccess, data);
        return data;
      },
      (error) => {
        const { response } = error;
        HttpHelper.handleFunction(
          onError,
          response && response.data
            ? response.data
            : `Error: the file appears to be corrupted or unavailable.`
        );
      }
    );
}

// async function getExcelFileData (onSuccess, onError) {
//     return HttpHelper.authApiFetch('/api/v1/media/xlsx/get-file-data', HttpHelper.method.GET, null, onSuccess, onError);
// }

async function getExcelFileData(filePath, onSuccess, onError) {
  const formData = {
    filePath: filePath,
  };
  const url = "/api/v1/media/xlsx/get-file-data";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError
  );
}

async function getFilePreview(formData, onSucces, onError) {
  const url = "/api/v1/media/xlsx/get-file-preview";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSucces,
    onError
  );
}

export const mediaService = {
  get: {
    getProjectMedias: getProjectMedias,
    reload: reloadProjectMedia,
    analyse: analyseProjectMedia,
    get: getMedia,
    checkStatus: checkMediaStatus,
    json: getMediaJson,
    csv: getMediaCsv,
    xlsx: getMediaXlsx,
    restoreCaptions: getRestoreCaptions,
    keywords: getKeywordReport,
    getScoreEmotion: getScoreEmotionGlobal,
    getInformationUrl: getInformationUrl,
    getExcelFileData: getExcelFileData,
  },
  delete: {
    delete: deleteProjectMedia,
  },
  post: {
    search: searchDatasource,
    create: createProjectMedia,
    createMultiple: createMultipleProjectDatasource,
    mongodb: {
      getMediaReport: getMediaReport,
    },
    uploadTxtFile: uploadTxtFile,
    getFilePreview: getFilePreview,
  },
  put: {
    update: updateMediaCaptions,
    updateTitle: updateProjectMedia,
  },
};
