import * as React from "react";
import { List, Datagrid, TextField, Filter, SearchInput, SelectInput } from "react-admin";
import { ACCOUNT_PLAN } from "../../app/constants";

const KPIFilters = (props) => {

  var planFilterChoices = [];
  for (const [key, value] of Object.entries(ACCOUNT_PLAN)) {
    planFilterChoices.push({ id: key, name: value });
  }

  return (
    <Filter {...props}>
      <SearchInput source="q" className="remove-line-height" alwaysOn />
      <SelectInput
        choices={planFilterChoices}
        label="Plan"
        source="plan"
        allowEmpty={false}
      />      
    </Filter>
  );
};

export const KPIList = (props) => (
  <List
    filters={<KPIFilters />}
    {...props}
    perPage={25}
    sort={{ field: "month", sortOrder: "ASC" }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField label="Month" source="month" />
      <TextField label="Account Id" source="account_id" />
      <TextField label="Account Name" source="account_name" />
      <TextField label="Plan" source="plan" />
      <TextField label="Project creation" source="project_creation" />
      <TextField label="Review extraction" source="review_extraction" />
      <TextField label="Open AI call" source="open_ai_call" />
      <TextField label="Max Users" source="max_users" />
      <TextField label="Max AIs" source="max_ais_used" />
      <TextField label="Max Reviews" source="max_reviews"/>
      <TextField label="Status" source="account_status" sortable={false} />
    </Datagrid>
  </List>
);
