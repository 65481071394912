import { Box, Hidden, Typography } from "@mui/material";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  TextInput,
  ReferenceInput,
  BooleanInput,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  SearchInput,
  BooleanField,
  Edit,
  SimpleForm,
  TopToolbar,
  Create,
  TabbedForm,
  EditButton,
  ListButton,
  Button,
  DeleteButton,
  useRecordContext,
  useNotify,
  useRedirect,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  NumberInput,
  Link,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import { getUrlParameters } from "./SectionList";

const postFilters = [
  <SearchInput source="q" className="remove-line-height" alwaysOn />,
  <BooleanInput label="Enabled" source="enable" />,
  <BooleanInput label="Visible" source="visible" />,
];

const ServiceShowButton = ({ record }) => [
  <EditButton label="Edit" record={record} />,
  <ShowButton label="Show" record={record} />,
];

export const ServiceList = (props) => (
  <List filters={postFilters} {...props} perPage={25}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField label="Service ID" source="id" />
      <TextField label="Name" source="service" />
      <TextField label="Display Order" source="sort_order" />
      <BooleanField label="Visible" source="isVisible" />
      <BooleanField label="Enable" source="isEnabled" />
      <TextField label="Updated at" source="updated_at" />
      <ServiceShowButton />
    </Datagrid>
  </List>
);

const ServiceShowActions = () => (
  <TopToolbar>
    <EditButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);

export const ServiceShow = (props) => (
  <Show actions={<ServiceShowActions />}>
    <SimpleShowLayout>
      <TextField label="Service ID" source="id" />
      <TextField label="Name" source="service" />
      <TextField label="Label" source="label" />
      <TextField label="Display Order" source="sort_order" />
      <BooleanField label="Visible" source="isVisible" />
      <BooleanField label="Enable" source="isEnabled" />
      <TextField label="Place holder" source="place_holder" />
      <TextField label="Updated at" source="updated_at" />
      <TextField label="Created at" source="created_at" />
    </SimpleShowLayout>
  </Show>
);

const ServiceEditActions = () => (
  <TopToolbar>
    <ShowButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);

const PostEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const ServiceAccountsEmptyState = () => {
  return (
    <Box sx={{ p: "30px" }}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          letterSpacing: "0.15px",
          mt: "5px",
          mb: "8px",
        }}
      >
        The account selection is currently empty, indicating that all accounts
        have access to this service based on their respective plans.
      </Typography>
    </Box>
  );
};

const serviceAccountsRowClick = (id, resource, record) => {
  return `/accounts/${record.account_id}/show`;
};

export const ServiceEdit = (props) => (
  <Edit mutationMode="pessimistic" actions={<ServiceEditActions />}>
    <TabbedForm warnWhenUnsavedChanges toolbar={<PostEditToolbar />}>
      <TabbedForm.Tab label="General">
        <TextInput disabled label="Name" source="service" />
        <NumberInput label="Display Order" source="sort_order" />
        <BooleanInput
          label="Visible"
          source="isVisible"
          helperText="The service is visible to all users"
        />
        <BooleanInput
          label="Enable"
          source="isEnabled"
          helperText="The service is enabled to all users"
        />
        <TextInput label="Place holder" source="place_holder" fullWidth />
        <ReferenceArrayInput
          source="product_category_ids"
          reference="categories"
        >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
        <TextInput disabled label="Updated at" source="updated_at" />
        <TextInput disabled label="Created at" source="created_at" />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Accounts" path="accounts">
        <ReferenceManyField
          reference="service-accounts"
          target="id"
          label="Account"
        >
          <Datagrid
            rowClick={serviceAccountsRowClick}
            bulkActionButtons={false}
            empty={<ServiceAccountsEmptyState />}
          >
            <TextField label="ID" source="id" />
            <TextField label="Account ID" source="account_id" />
            <TextField label="Product Source ID" source="product_source_id" />
            <TextField label="Name" source="account_name" />
            <DeleteButton redirect="" />
          </Datagrid>
        </ReferenceManyField>
        <CreateRelatedAccountButton />
      </TabbedForm.Tab>
    </TabbedForm>
  </Edit>
);

export const CreateRelatedAccountButton = () => {
  const product = useRecordContext();
  return (
    <Button
      component={Link}
      to={`/service-accounts/create?serviceId=${product.id}`}
      color="primary"
      label="Create"
    >
      <AddIcon />
    </Button>
  );
};

// Note we declared the function outside the component to avoid rerenders

const optionRenderer = (choice) => `${choice.id} - ${choice.account_name}`;

export const AccountProductSourceCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const urlParams = getUrlParameters();
  const serviceId = urlParams["serviceId"];

  const onSuccess = () => {
    // display a notification to confirm the creation
    notify("ra.notification.created");
    redirect(`/services/${serviceId}/accounts`);
  };

  return (
    <Create mutationOptions={{ onSuccess, meta: { serviceId: serviceId } }}>
      <SimpleForm>
        <Hidden source="product_id" />
        <ReferenceInput
          label="Account"
          source="account_id"
          reference="accounts"
          sort="ASC"
        >
          <AutocompleteInput optionText={optionRenderer} optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
