import React, { Fragment } from "react";
import moment from "moment-timezone";
import "./list.css";
import { withTranslation } from "react-i18next";
import Link from "../Link";
import RoundSelect from "../roundSelect/RoundSelect";
import { PROJECT_DETAIL } from "../../app/routing";
import { PROJECT_STATUS } from "../../app/constants";
import defaultImage from "../../res/images/placeholder-img.svg";
import CONSUMER from "../../res/images/icons/picto-consumer.svg";
import LAB_EXPERT from "../../res/images/icons/picto-expert.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material";
import MediaHelper from "../../app/helpers/MediaHelper";
import loadArrow from "../../res/images/icons/cached-filled.svg";
class ProjectListItem extends React.Component {
  constructor(props) {
    super(props);

    this.getRoundSelectList = this.getRoundSelectList.bind(this);
  }

  getRoundSelectList() {
    const { t } = this.props;
    const item = this.props.item;
    if (item.status !== PROJECT_STATUS.LOCKED) {
      if (item.isArchived) {
        return {
          other: [
            {
              label: t("Unarchive project"),
              withLoader: true,
              action: (onThen) => {
                this.props.onUnArchiveItem(item.id, onThen);
              },
            },
            {
              label: t("Delete project…"),
              action: () => {
                this.props.onOpenDeleteModal(item.id);
              },
              style: { color: "#e04f77" },
            },
          ],
        };
      } else {
        return {
          links: [{ label: t("View project"), link: PROJECT_DETAIL(item.id) }],
          other: [
            {
              label: t("Archive project"),
              withLoader: true,
              action: (onThen) => {
                this.props.onArchiveItem(item.id, onThen);
              },
            },
            {
              label: t("Delete project…"),
              action: () => {
                this.props.onOpenDeleteModal(item.id);
              },
              style: { color: "#e04f77" },
            },
          ],
        };
      }
    } else {
      if (item.isArchived) {
        return {
          other: [
            {
              label: t("Unarchive project"),
              withLoader: true,
              action: (onThen) => {
                this.props.onUnArchiveItem(item.id, onThen);
              },
            },
            {
              label: t("Delete project…"),
              action: () => {
                this.props.onOpenDeleteModal(item.id);
              },
              style: { color: "#e04f77" },
            },
          ],
        };
      } else {
        return {
          other: [
            {
              label: t("Archive project"),
              withLoader: true,
              action: (onThen) => {
                this.props.onArchiveItem(item.id, onThen);
              },
            },
            {
              label: t("Delete project…"),
              action: () => {
                this.props.onOpenDeleteModal(item.id);
              },
              style: { color: "#e04f77" },
            },
          ],
        };
      }
    }
  }

  getImageStyle() {
    return { maxHeight: "18px", width: "18px" };
  }

  render() {
    const { t } = this.props;
    const item = this.props.item;
    const imageStyle = this.getImageStyle();
    let listItemsClasses = ["project-list-item", "transition-color"];
    if (item.isArchived) {
      listItemsClasses.push("archived");
    }
    if (item.status === PROJECT_STATUS.LOCKED) {
      listItemsClasses.push("locked");
    }

    const pictureStyle = item.preview?.picture
      ? {
          backgroundImage: "url('" + item.preview.picture + "')",
          backgroundSize: "contain",
        }
      : {
          backgroundImage: "url('" + defaultImage + "')",
          backgroundSize: "120%",
        };

    const roundSelectList = this.getRoundSelectList();

    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        padding: "5px",
        borderRadius: "2px",
        backgroundColor: "#424242",
      },
    }));

    let product_score = "-";

    if (
      item.statistics.product_score != null &&
      item.statistics.product_score != undefined
    ) {
      product_score = Math.round(item.statistics.product_score * 100) / 100;
    }

    return (
      <div className={listItemsClasses.join(" ")} key={this.props.myKey}>
        {/* image */}
        <div className="position-relative">
          <div
            className="archive-opacity picture background-image-contain"
            style={pictureStyle}
          />
          {item.status === PROJECT_STATUS.LOCKED && (
            <div className="banner-locked-project">{t("Locked")}</div>
          )}
        </div>

        {/* nom datasources */}
        <div className="archive-opacity position-relative  padding-horizontal-16 padding-top-12">
          <div className="font-size-18 color-424242 font-inter-600 font-weight-600 text-ellipsis padding-bottom-5">
            {item.name}
          </div>
          <div className="test-type">
            {item.typology.length !== 0 &&
              item.typology.map((elm, i) => {
                return (
                  <div
                    className="display-flex align-items-center gap-4"
                    key={i}
                  >
                    <img
                      src={elm === "CONSUMER" ? CONSUMER : LAB_EXPERT}
                      alt=""
                    />
                    <div className="font-size-13 color-7f7f7f font-inter-400">
                      {MediaHelper.test[elm]}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="mediaList padding-top-10">
            {item.datasources?.map((media, i) => {
              if (i < 3) {
                return (
                  <div className="mediaItem" key={`${item.id}-${i}`}>
                    <img
                      src={process.env.REACT_APP_API_URL + media.icon}
                      alt=""
                      style={imageStyle}
                    />
                    <div>{media.label}</div>
                  </div>
                );
              }
            })}
            {item.datasources?.length > 3 && (
              <HtmlTooltip
                title={
                  <Fragment>
                    {item.datasources.map((media, i) => {
                      if (i > 2) {
                        return (
                          <div className="mediaItem mediaItem-tooltip" key={i}>
                            <img
                              src={process.env.REACT_APP_API_URL + media.icon}
                              alt=""
                              style={imageStyle}
                            />
                            <div>{media.label}</div>
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </Fragment>
                }
                placement="bottom-start"
              >
                <div className="mediaItem">
                  <div>+{item.datasources.length - 3}</div>
                </div>
              </HtmlTooltip>
            )}
          </div>
        </div>

        <div
          className="bottom"
          style={item.typology.length === 0 ? { marginTop: "16px" } : {}}
        >
          {/*score et reviews */}
          <div id="scoreAndReviews">
            <div className="number-box">
              <p>REVIEWS</p>
              <h3>
                {item.typology.length === 0
                  ? "-"
                  : item.statistics.percent_data.total_obs === null
                  ? "0"
                  : item.statistics.percent_data.total_obs}
              </h3>
            </div>
            <div className="separator"></div>
            <div className="number-box">
              <p>SCORE</p>
              <h3>{product_score}</h3>
            </div>
          </div>

          {/* date d'upload / derniere modification */}
          <div className="timeAndSelect">
            {item.status_message === null && (
              <div
                className="font-size-13 color-7f7f7f font-inter-400"
                title={moment
                  .utc(item.updated_at)
                  .local()
                  .format("MMMM Do YYYY, h:mm:ss a")}
              >
                {moment.utc(item.updated_at).local().fromNow(true) === "Now"
                  ? moment().from(moment.utc(item.updated_at)).slice(3)
                  : `${moment()
                      .from(moment.utc(item.updated_at))
                      .slice(3)} ago`}
              </div>
            )}
            {item.status_message !== null && (
              <div className="font-size-13 color-7f7f7f updateMessage">
                <img className="rotate-img" src={loadArrow} alt="" />
                <span className="font-weight-500 font-size-13">
                  {item.status_message}
                </span>
              </div>
            )}

            {roundSelectList && (
              <div className="roundSelect">
                <RoundSelect lists={roundSelectList} />
              </div>
            )}
          </div>
        </div>

        {!item.isArchived && item.status !== PROJECT_STATUS.LOCKED && (
          <Link className="link-absolute" to={PROJECT_DETAIL(item.id)} />
        )}
      </div>
    );
  }
}

export default withTranslation()(ProjectListItem);
