import React, { Fragment } from "react";
import "./roundSelect.css";
import { withTranslation } from "react-i18next";
import Link from "../Link";
import Loader from "../loader/Loader";

class RoundSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingActions: {},
    };
    this.wrapperRef = React.createRef();
    this.loadingActionFunction = this.loadingActionFunction.bind(this);
    this.getContentClass = this.getContentClass.bind(this);
  }

  loadingActionFunction(index, func, withLoader) {
    if (withLoader) {
      let loadingActions = Object.assign({}, this.state.loadingActions);
      loadingActions[index] = true;
      this.setState({
        loadingActions: loadingActions,
      });
      return func(() => {
        let newLoadingActions = Object.assign({}, this.state.loadingActions);
        delete newLoadingActions[index];
        this.setState({
          loadingActions: newLoadingActions,
        });
      });
    } else {
      return func();
    }
  }

  getContentClass() {
    let fullContentClasses = ["full-content-round-select", "transition"];
    if (this.props.className) {
      fullContentClasses.push(this.props.className);
    }
    return fullContentClasses;
  }

  render() {
    const fullContentClasses = this.getContentClass();
    const lists = this.props.lists;
    let listContent = [];
    let i = 0;
    for (const [key, list] of Object.entries(lists)) {
      listContent.push(
        <Fragment key={`roundSelect-fragment-${key}`}>
          {i !== 0 && <div className="separator" />}
          <ul key={`roundSelect-ul-${key}`}>
            {list.map((item, j) => {
              let itemClasses = ["transition-color"];
              if (item.className) {
                itemClasses.push(item.className);
              }
              if (item.action && typeof item.action == "function") {
                return (
                  <li
                    key={`roundSelect-li-${j}`}
                    className={itemClasses.join(" ")}
                    style={item.style}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.loadingActionFunction(
                        j,
                        item.action,
                        item.withLoader
                      );
                    }}
                  >
                    {item.withLoader && this.state.loadingActions[j] && (
                      <div className="content-loader">
                        <Loader className="loader-button" color="#000000" />
                      </div>
                    )}
                    {!this.state.loadingActions[j] && item.label}
                    {!this.state.loadingActions[j] && item.icon}
                  </li>
                );
              }
              if (item.link) {
                return (
                  <li
                    key={`roundSelect-li-${j}`}
                    className={itemClasses.join(" ")}
                    style={item.style}
                  >
                    <Link to={item.link}>
                      {item.label}
                      {item.icon}
                    </Link>
                  </li>
                );
              }
              return (
                <li
                  key={`roundSelect-li-${j}`}
                  className={itemClasses.join(" ")}
                  style={item.style}
                >
                  {item.label}
                  {item.icon}
                </li>
              );
            })}
          </ul>
        </Fragment>
      );
      i++;
    }
    return (
      <div ref={this.wrapperRef} className={fullContentClasses.join(" ")}>
        <div className="position-relative">
          <div
            className="content transition-color" /*onClick={this.onToggleOpen}*/
          >
            <div className="dot transition-color" />
            <div className="dot transition-color" />
            <div className="dot transition-color" />
          </div>
        </div>
        {!this.props.disableHover && (
          <div className="content-list">
            <div className="list transition-opacity text-align-left">
              {listContent}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(RoundSelect);
