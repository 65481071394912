import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/loader/Loader";
import InformationsUrl from "./InformationsUrl";
import { TextField } from "@mui/material";

class FormYoutubeUrl extends React.Component {
  render() {
    const {
      t,
      formData,
      handleChangeUrl,
      submitLoading,
      placeHolder,
      urlLoading,
      urlData,
      displayInformationUrl,
      onHideInformationUrl,
    } = this.props;
    return (
      <Fragment>
        <div className="padding-top-20" />
        <div className="position-relative padding-bottom-10">
          <TextField
            placeholder={placeHolder}
            value={formData.url}
            onChange={handleChangeUrl}
            type="text"
            hiddenLabel={true}
            name="url"
            disabled={submitLoading}
            sx={{ width: "100%" }}
          />
        </div>
        <div className="content-url-response">
          {urlLoading && (
            <div className="text-align-center position-relative padding-vertical-30">
              <Loader />
            </div>
          )}
          {!urlLoading && urlData && displayInformationUrl && (
            <InformationsUrl
              selectedProductSource={this.props.selectedProductSource}
              data={urlData}
              onCloseClick={onHideInformationUrl}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(FormYoutubeUrl);
