import "../settings.css";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ACCOUNT_PLAN, globalConstants } from "../../../app/constants";
import { connect } from "react-redux";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import LoadingView from "../../loading/LoadingView";

import { withTranslation } from "react-i18next";
import SettingsMenu from "../elements/SettingsMenu";

import { ROUTES, SETTINGS_PLAN_AND_BILLING } from "../../../app/routing";

import HttpHelper from "../../../app/helpers/HttpHelper";
import UserHelper from "../../../app/helpers/UserHelper";
import Loader from "../../../components/loader/Loader";

import OverviewTab from "./elements/OverviewTab";
import InvoiceTab from "./elements/InvoicesTab";
import PaymentMethodsTab from "./elements/PaymentMethodsTab";
import { accountService } from "../../../app/services/account.service";
import SettingsLoadingView from "../../loading/SettingsLoadingView";
import { AccountDataManagerContext } from "../../../dataManagers/accountDataManager";

const TABS = {
  OVERVIEW: "Overview",
  INVOICES: "Invoices",
  PAYMENT_METHODS: "Payment_methods",
};

class AccountsAndBillingView extends React.Component {
  static contextType = AccountDataManagerContext;
  constructor(props) {
    super(props);
    const activeTab = props.match.params.tab
      ? props.match.params.tab
      : TABS.OVERVIEW;
    this.state = {
      isLoaded: false,
      inlineLoading: false,
      activeTab: activeTab,
      account: {},
    };
    this.fetchInitial = this.fetchInitial.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }
    const isAdmin = UserHelper.isAdmin();
    if (!isAdmin) {
      this.props.history.push(ROUTES.UNAUTHORIZED);
    }
    accountService.getAccountData(
      (response) => {
        this.setState({
          isLoaded: true,
          inlineLoading: false,
          account: response,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
          inlineLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  fetchOverview() {
    if (this._ismounted) {
      this.setState({ inlineLoading: false });
    }
  }

  onTabClick(item) {
    if (this.state.activeTab !== item.value) {
      this.setState({ activeTab: item.value });
      this.props.history.replace({
        pathname: SETTINGS_PLAN_AND_BILLING(item.value),
      });
      if (item.value === TABS.OVERVIEW && !this.state.offre) {
        this.fetchOverview();
      }
    }
  }

  render() {
    const { t } = this.props;
    let { activeTab } = this.state;
    let page_title = `${t("Plan & Billing")} - ${
      globalConstants.WEBSITE_TITLE
    }`;
    let tabs = [
      {
        active: activeTab === TABS.OVERVIEW,
        label: t("Plan Overview"),
        value: TABS.OVERVIEW,
      },
      {
        active: activeTab === TABS.INVOICES,
        label: t("Invoices"),
        value: TABS.INVOICES,
      },
      {
        active: activeTab === TABS.PAYMENT_METHODS,
        label: t("Payment methods"),
        value: TABS.PAYMENT_METHODS,
      },
    ];

    const planKey = this.context.accountDataManagerState.plan;

    if (planKey == ACCOUNT_PLAN.MANUAL_BILLING) {
      activeTab = TABS.OVERVIEW;
      tabs = [
        {
          active: activeTab === TABS.OVERVIEW,
          label: t("Plan Overview"),
          value: TABS.OVERVIEW,
        },
      ];
    }

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <Header />
          <div className="padding-top-30" />
          <div
            className="central-content settings-content"
            key={this.state.formKey}
          >
            <div className="left">
              <SettingsMenu
                current={ROUTES.SETTINGS_PLAN_AND_BILLING_BASE}
                history={this.props.history}
              />
            </div>
            <div className="right">
              <div className="padding-top-32" />

              <div className="font-inter-700 font-size-28 color-000 padding-bottom-40">
                {t("Plan & Billing")}
              </div>
              <div className="content-settings-tabs">
                {tabs.map((item, i) => {
                  let classNames = ["tab", "transition", "font-inter-400"];
                  if (item.active) {
                    classNames.push("active");
                  }
                  return (
                    <div
                      key={`tab-${item.value}`}
                      className={classNames.join(" ")}
                      onClick={() => {
                        this.onTabClick(item);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
              {this.state.inlineLoading && (
                <div className="padding-vertical-30">
                  <Loader />
                </div>
              )}
              {!this.state.inlineLoading && activeTab === TABS.OVERVIEW && (
                <OverviewTab
                  addToast={this.props.addToast}
                  account={this.state.account}
                  history={this.props.history}
                  location={this.props.location}
                />
              )}
              {!this.state.inlineLoading && activeTab === TABS.INVOICES && (
                <InvoiceTab
                  addToast={this.props.addToast}
                  account={this.state.account}
                  history={this.props.history}
                  location={this.props.location}
                  fetchAccount={this.fetchInitial}
                />
              )}
              {!this.state.inlineLoading &&
                activeTab === TABS.PAYMENT_METHODS && (
                  <PaymentMethodsTab
                    addToast={this.props.addToast}
                    location={this.props.location}
                    history={this.props.history}
                    fetchAccount={this.fetchInitial}
                  />
                )}
            </div>
          </div>
          <div className="padding-top-20" />
          <Footer />
        </Fragment>
      );
    }
    return (
      <SettingsLoadingView
        title={page_title}
        withHeader={true}
        rightTitle={t("Plan & Billing")}
        settingsMenuProps={{
          current: ROUTES.SETTINGS_PLAN_AND_BILLING_BASE,
          history: this.props.history,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(
  connect(mapStateToProps)(AccountsAndBillingView)
);
