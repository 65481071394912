import { globalConstants } from "../constants";
import { ROUTES } from "../routing";
import { userService } from "../services/user.service";
import UserHelper from "./UserHelper";

class HttpHelper {}

HttpHelper.method = {
  POST: "POST",
  PUT: "PUT",
  GET: "GET",
  DELETE: "DELETE",
};

HttpHelper.paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

HttpHelper.getUrlParameters = (locationSearch) => {
  if (!locationSearch) {
    return {};
  }
  const urlParams = new URLSearchParams(locationSearch);
  const entries = urlParams.entries();
  return HttpHelper.paramsToObject(entries);
};

HttpHelper.addParametersToUrl = (baseUrl, params) => {
  if (!params || (params && Object.keys(params).length === 0)) {
    return baseUrl;
  }
  for (const [key, value] of Object.entries(params)) {
    if (value == null) {
      // je remove les value null car le null va se transformer en string 'null' dans l'url
      delete params[key];
    }
  }
  return Object.keys(params).length > 0
    ? baseUrl + "?" + new URLSearchParams(params)
    : baseUrl;
};

HttpHelper.authHeader = (customHeaders) => {
  customHeaders = customHeaders || {};
  // return authorization header with jwt token
  const user = UserHelper.getUser();
  const currentAccount = UserHelper.getCurrentAccount();
  const currentAccountId = currentAccount ? currentAccount.id : -1;
  if (user && user.access_token) {
    return Object.assign(
      { "x-access-token": user.access_token, "account-id": currentAccountId },
      customHeaders
    );
  } else {
    return customHeaders;
  }
};

HttpHelper.handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok && HttpHelper.manageResponseStatus(response, false)) {
      return Promise.reject(data);
    }
    return data;
  });
};

HttpHelper.handleLoggedVanillaResponse = (response) => {
  if (!response.ok) {
    if (HttpHelper.manageResponseStatus(response)) {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        const error = data && data.message ? data.message : response.statusText;
        return Promise.reject(error);
      });
    }
  }
  return response;
};

HttpHelper.handleLoggedResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (HttpHelper.manageResponseStatus(response)) {
        let error = null;
        if (data.message && data.custom) {
          error = { message: data.message, custom: data.custom };
        } else if (data.message) {
          error = data.message;
        } else {
          error = response.statusText;
        }

        return Promise.reject(error);
      }
    }

    return data;
  });
};

HttpHelper.handleLoggedFileResponse = (response) => {
  if (response.ok) {
    return response;
  }
  return HttpHelper.handleLoggedResponse(response);
};

HttpHelper.manageResponseStatus = (response, check401 = true) => {
  console.log("check response status", response.status);
  if (check401 && response.status === 401) {
    // auto logout if 401 response returned from api
    userService.logout();
    window.location.reload();
    return false;
  } else if (response.status === 404) {
    console.log("repone status 401");
    window.location.href = ROUTES.OOPS;
    return false;
  } else if (response.status === 500) {
    window.location.href = ROUTES.ARF;
    return false;
  } else if (response.status === 403) {
    window.location.href = ROUTES.HOME;
    return false;
  }
  return true;
};

HttpHelper.getErrorMessage = (error) => {
  return typeof error == "string" ? error : error.message;
};

HttpHelper.getErrorStatus = (error) => {
  return typeof error == "string" ? "unknown" : error.status;
};

HttpHelper.getAuthRequestOptions = (
  method,
  formData = null,
  customHeaders = null
) => {
  if (formData && ["POST", "PUT"].includes(method)) {
    return {
      method: method,
      headers: HttpHelper.authHeader(customHeaders),
      body: JSON.stringify(formData),
    };
  }
  return {
    method: method,
    headers: HttpHelper.authHeader(),
  };
};

HttpHelper.getRequestOptions = (method, formData = null) => {
  if (formData && ["POST", "PUT"].includes(method)) {
    return {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };
  }
  return {
    method: method,
  };
};

HttpHelper.authFetch = (url, requestOptions, onSuccess, onError) => {
  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (response) => {
        HttpHelper.handleFunction(onSuccess, response);
        return response;
      },
      (error) => {
        HttpHelper.handleFunction(onError, error);
      }
    )
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
};

HttpHelper.fetch = (url, requestOptions, onSuccess, onError) => {
  return fetch(url, requestOptions)
    .then(HttpHelper.handleResponse)
    .then(
      (response) => {
        HttpHelper.handleFunction(onSuccess, response);
        return response;
      },
      (error) => {
        HttpHelper.handleFunction(onError, error);
      }
    )
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
};

HttpHelper.handleFunction = (func, data) => {
  if (func) {
    func(data);
  }
};

HttpHelper.getApiUrl = (path, method, formData = null) => {
  const baseUrl = `${globalConstants.API_URL}${path}`;
  if (formData && method === HttpHelper.method.GET) {
    return HttpHelper.addParametersToUrl(baseUrl, formData);
  }
  return baseUrl;
};

HttpHelper.authApiFetch = (
  path,
  method = "GET",
  formData = null,
  onSuccess = null,
  onError = null,
  signal = null
) => {
  const requestOptions = HttpHelper.getAuthRequestOptions(method, formData);

  if (signal instanceof AbortSignal) {
    requestOptions.signal = signal;
  }

  const url = HttpHelper.getApiUrl(path, method, formData);
  return HttpHelper.authFetch(url, requestOptions, onSuccess, onError);
};

HttpHelper.apiFetch = (
  path,
  method = "GET",
  formData = null,
  onSuccess = null,
  onError = null
) => {
  const requestOptions = HttpHelper.getRequestOptions(method, formData);
  const url = `${globalConstants.API_URL}${path}`;
  return HttpHelper.fetch(url, requestOptions, onSuccess, onError);
};

export default HttpHelper;
