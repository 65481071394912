import React from "react";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/loader/Loader";
import ListOptSelect from "../../../components/listOptSelect/ListOptSelect";
import WorkspaceListItem from "../../../components/list/WorkspaceListItem";
import MuiSwitch from "../../../components/switch/MuiSwitch";

class ItemsList extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getScoreTooltipsRefs = this.getScoreTooltipsRefs.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
  }

  handleClickOutside(event) {
    let tooltips = document.getElementsByClassName("base-tooltip");
    if (tooltips && tooltips[0] && tooltips[0].contains(event.target)) {
      // click on tooltip
      return null;
    }
    let selectionTooltipEl = document.getElementById("selection-tooltip");
    if (
      !selectionTooltipEl ||
      (selectionTooltipEl && !selectionTooltipEl.contains(event.target))
    ) {
      this.props.getHighlightedSelectionText();
    }
    let scrollContents = document.getElementsByClassName(
      "workspace-scroll-content"
    );
    if (
      scrollContents &&
      scrollContents[0] &&
      !scrollContents[0].contains(event.target)
    ) {
      this.props.onCloseItem();
    }
  }

  getScoreTooltipsRefs() {
    let allRefs = [];
    for (const item of this.props.listItems) {
      allRefs.push(React.createRef());
    }

    if (this.props.updateScoreTooltipsRefs) {
      this.props.updateScoreTooltipsRefs(allRefs);
    }

    return allRefs;
  }

  render() {
    const project = this.props.project;
    const listItems = this.props.listItems;
    const inlineLoading = this.props.inlineLoading;
    const parentLoaderClass =
      listItems.length > 0
        ? "text-align-center padding-vertical-20"
        : "text-align-center padding-vertical-80";
    const scoreTooltipRefs = this.getScoreTooltipsRefs();

    return (
      <div
        className={`central-content ${
          this.props.print ? "" : "padding-top-20"
        }`}
      >
        <div className="padding-top-24 content-list-media-items">
          {listItems.map((item, i) => {
            const scoreTooltipRef = scoreTooltipRefs[i];
            return (
              <WorkspaceListItem
                print={this.props.print}
                key={`workspace-list-item-${i}`}
                projectId={project.id}
                datasourceId={this.props.datasourceId}
                maxRange={project.max_range_value}
                item={item}
                displayOriginalReviews={this.props.displayOriginalReviews}
                onUpdateItemScore={this.props.onUpdateItemScore}
                updateScoreLoading={this.props.updateScoreLoading}
                onOpenItem={this.props.onOpenItem}
                selectedKeywords={this.props.selectedKeywords}
                highlightedKeyword={this.props.highlightedKeyword}
                getHighlightedSelectionText={
                  this.props.getHighlightedSelectionText
                }
                onOpenUpdateKeywordClassModal={
                  this.props.onOpenUpdateKeywordClassModal
                }
                scoreTooltipRefs={scoreTooltipRefs}
                scoreTooltipRef={scoreTooltipRef}
                scoreTooltipRefIndex={i}
              />
            );
          })}
        </div>
        {inlineLoading && (
          <div className={parentLoaderClass}>
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(ItemsList);
