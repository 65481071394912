import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import {
  ACCOUNT_PLAN,
  globalConstants,
  PLAN_JSON_LOGIC_KEYS,
  PROJECT_STATUS,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";
import Header from "../../components/header/Header";

import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";

import "./createProject.css";
import { classService } from "../../app/services/class.service";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";
import MiniProjectListItem from "../../components/list/MiniProjectListItem";
import HttpHelper from "../../app/helpers/HttpHelper";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import Link from "../../components/Link";
import { PROJECT_EDIT, ROUTES } from "../../app/routing";
import { Checkbox, TextField } from "@mui/material";
import STypography from "../../components/SMui/STypography";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import UserHelper from "../../app/helpers/UserHelper";

class EditClassView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isIntegrated: false,
      formData: {
        title: "",
        keywords_txt: "",
        consumer: true,
        lab_expert: true,
        product_type: [],
        display_name: "",
      },
      original_title: "",
      original_captions: "",
      original_products: [],
      original_consumer: null,
      original_lab_expert: null,
      plan: ACCOUNT_PLAN.FREE,
      totalReviews: 0,
      maxReviews: 0,
      canEditIntegratedClass: false,
      canEditCustomClass: false,
      submitLoading: false,
      projects: [],
    };
    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDisplayName = this.handleChangeDisplayName.bind(this);
    this.handleChangeWords = this.handleChangeWords.bind(this);
    this.isModeEdition = this.isModeEdition.bind(this);
    this.isSaveEnabled = this.isSaveEnabled.bind(this);
    this.titleH1Handler = this.titleH1Handler.bind(this);
    this.libelleNbProjectsHandler = this.libelleNbProjectsHandler.bind(this);
    this.onCheckConsumer = this.onCheckConsumer.bind(this);
    this.onCheckExpert = this.onCheckExpert.bind(this);
    this.handleChangeProductType = this.handleChangeProductType.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.CUSTOMIZE_INTEGRATED_CLASS,
      {},
      (response) => {
        this.setState({
          canEditIntegratedClass:
            response.status === "success" &&
            response.data.plan != ACCOUNT_PLAN.FREE,
          canEditCustomClass:
            response.status === "success" &&
            response.data.plan != ACCOUNT_PLAN.FREE,
          plan: response.data.plan,
          totalReviews: response.current_data.current_reviews_for_account,
          maxReviews: response.current_data.max_reviews_for_account,
        });
        this.fetchData();
      },
      (error) => {
        if (this._ismounted) {
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
          this.fetchData();
        }
      }
    );
  }

  // FETCH CLASS DATA HANDLER --------------------------------------
  fetchData() {
    classService.get.get(
      this.props.match.params.class_id,
      (response) => {
        let products = [];
        for (let i = 0; i < response.products?.length ?? 0; i++) {
          products.push({
            value: response.products[i].id,
            label: response.products[i].title,
          });
        }
        const newProjects = response.projects.filter(
          (item) => !item.isArchived && item.status !== PROJECT_STATUS.LOCKED
        );
        this.setState({
          isLoaded: true,
          isIntegrated: response.isIntegrated,
          formData: {
            title: response.title,
            keywords_txt: response.keywords_txt,
            consumer: response.consumer,
            lab_expert: response.lab_expert,
            product_type: products,
            display_name: response.display_name,
          },
          original_title: response.title,
          original_display_name: response.display_name,
          original_captions: response.keywords_txt,
          original_consumer: response.consumer,
          original_products: products,
          original_lab_expert: response.lab_expert,
          projects: newProjects,
        });
      },
      (error) => {
        if (this._ismounted) {
          this.setState({ isLoaded: true });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      }
    );
  }

  // INPUT NAME HANDLER ---------------------------------------------
  handleChangeName(event) {
    let classTitle = event.target.value;
    let words = this.state.formData.keywords_txt;
    this.setState({
      formData: {
        ...this.state.formData,
        title: classTitle,
        keywords_txt: words,
      },
    });
  }

  // INPUT NAME HANDLER ---------------------------------------------
  handleChangeDisplayName(event) {
    let words = this.state.formData.keywords_txt;
    let title = this.state.formData.title;
    let display_name = event.target.value;
    this.setState({
      formData: {
        ...this.state.formData,
        display_name: display_name,
        keywords_txt: words,
        title: title,
      },
    });
  }

  // INPUT WORDS HANDLER --------------------------------------------
  handleChangeWords(event) {
    let newWords = event.target.value;
    let title = this.state.formData.title;
    let display_name = this.state.formData.display_name;
    this.setState({
      isModified: newWords !== this.state.original_captions,
      formData: {
        ...this.state.formData,
        keywords_txt: newWords,
        title: title,
        display_name: display_name,
      },
    });
  }

  // SUBMIT BUTTON MODIF --------------------------------------
  onSubmitModif = (e) => {
    let formData = {
      name: this.state.formData.title,
      display_name: this.state.formData.display_name,
      keywords_txt: this.state.formData.keywords_txt,
      consumer: this.state.formData.consumer,
      lab_expert: this.state.formData.lab_expert,
      product_type: this.state.formData.product_type,
    };
    if (formData) {
      this.setState({ submitLoading: true });
      classService.put.update(
        this.props.match.params.class_id,
        formData,
        (response) => {
          setTimeout(() => {
            this.props.addToast(
              'Class "' + formData.name + '" successfully updated',
              { type: "success", autoDismiss: true }
            );
          }, 800);
          this.props.history.push(PROJECT_EDIT(this.props.match.params.id));
        },
        (error) => {
          if (this._ismounted) {
            this.setState({ submitLoading: false });
            this.props.addToast(HttpHelper.getErrorMessage(error), {
              type: "error",
              autoDismiss: true,
            });
          }
        }
      );
    }
  };

  isSaveEnabled() {
    if (
      this.state.original_title !== "Excluded words" &&
      (this.state.formData.title == "" ||
        this.state.formData.keywords_txt == "" ||
        this.state.formData.display_name == "" ||
        (!this.state.formData.consumer && !this.state.formData.lab_expert))
    ) {
      return false;
    }
    if (
      this.state.original_title === "Excluded words" &&
      (this.state.formData.title == "" ||
        this.state.formData.keywords_txt == "" ||
        this.state.formData.display_name == "")
    ) {
      return false;
    }
    return (
      this.state.formData.title !== this.state.original_title ||
      this.state.formData.display_name !== this.state.original_display_name ||
      this.state.formData.keywords_txt !== this.state.original_captions ||
      this.state.formData.consumer !== this.state.original_consumer ||
      this.state.formData.lab_expert !== this.state.original_lab_expert
    );
  }

  isModeEdition() {
    return (
      (this.state.isIntegrated && this.state.canEditIntegratedClass) ||
      (this.state.canEditCustomClass && !this.state.isIntegrated)
    );
  }

  titleH1Handler(is_mode_edition) {
    const { t } = this.props;
    return is_mode_edition ? t("Edit Class") : t("View Class");
  }

  libelleNbProjectsHandler() {
    const { t } = this.props;
    return this.state.projects.length > 1
      ? t("In {{count}} projects", { count: this.state.projects.length })
      : t("In {{count}} project", { count: this.state.projects.length });
  }

  onCheckConsumer(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        consumer: !this.state.formData.consumer,
      },
    });
  }

  onCheckExpert(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        lab_expert: !this.state.formData.lab_expert,
      },
    });
  }

  handleChangeProductType(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        product_type: value,
      },
    });
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Class")} - ${globalConstants.WEBSITE_TITLE}`;

    if (this.state.isLoaded) {
      const is_mode_edition = this.isModeEdition();
      let title_h1 = this.titleH1Handler(is_mode_edition);
      page_title = `${title_h1} - ${globalConstants.WEBSITE_TITLE}`;
      const buttonIsEnabled = this.isSaveEnabled();
      const libelleNbProjects = this.libelleNbProjectsHandler();
      const { plan, totalReviews, maxReviews } = this.state;
      const isAdmin = UserHelper.isAdmin();
      let exceedPercent = ((totalReviews - maxReviews) / maxReviews) * 100;
      exceedPercent = exceedPercent?.toFixed();

      let messageNoEdition = (
        <Trans i18nKey="on_free_project_limit">
          You are on the Free Plan. Please{" "}
          <Link
            to={ROUTES.PLAN_CHOOSE}
            className="text-decoration-underline color-fff"
          >
            upgrade
          </Link>{" "}
          to Pro or Business plan if you want to customize this class.
        </Trans>
      );

      if (plan != ACCOUNT_PLAN.FREE) {
        const messagelink =
          plan == ACCOUNT_PLAN.ENTERPRISE ? (
            <STypography
              onClick={() => IntercomHelper.trackEvent("contact-success-team")}
              className="text-decoration-underline color-fff"
              sx={{
                display: "inline",
                fontWeight: 400,
                textTransform: "none",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Trans i18nKey="contact_our_success_team">
                Contact our success team
              </Trans>
            </STypography>
          ) : (
            <Link
              to={ROUTES.SETTINGS_PLAN_AND_BILLING_OVERVIEW}
              className="text-decoration-underline color-fff"
            >
              Add More Reviews
            </Link>
          );

        messageNoEdition = isAdmin ? (
          <Trans i18nKey="hit_storage_media_limit_classe">
            You have exceeded your limit by{" "}
            <span className="font-weight-600">{{ exceedPercent }}%</span>.{" "}
            {messagelink} to keep refining your analysis!
          </Trans>
        ) : (
          <Trans i18nKey="hit_storage_media_limit_classe">
            You have exceeded your limit by{" "}
            <span className="font-weight-600">{{ exceedPercent }}%</span>. Free
            up space to keep refining your analysis!
          </Trans>
        );
      }
      return (
        <Fragment>
          <Header isProject={true} />
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <div className="padding-top-60 padding-bottom-20 responsive-920-padding-top-20">
            <div className="central-content">
              <div className="font-size-36 font-inter-700 color-000 padding-bottom-40">
                {title_h1}
              </div>
              <div className="content-column">
                <div className="column-percent-65 responsive-900">
                  <form>
                    <label className="padding-bottom-4 display-block font-inter-500 color-969696 font-size-15">
                      {t("Class name")}{" "}
                      <span className="font-size-13 color-aaa font-inter-400">
                        {" "}
                        •{" "}
                        {this.state.isIntegrated
                          ? t("integrated")
                          : t("custom")}
                      </span>
                    </label>
                    <TextField
                      placeholder="Name"
                      value={this.state.formData.title}
                      onChange={this.handleChangeName}
                      type="text"
                      disabled={
                        this.state.submitLoading ||
                        this.state.isIntegrated ||
                        !is_mode_edition
                      }
                      sx={{ width: "100%" }}
                    />
                    <label className="padding-top-15 padding-bottom-4 display-block font-inter-500 color-969696 font-size-15">
                      {t("Display name")}
                    </label>
                    <TextField
                      placeholder="Display name"
                      disabled={!is_mode_edition}
                      value={this.state.formData.display_name}
                      onChange={this.handleChangeDisplayName}
                      type="text"
                      maxLength="65"
                      sx={{ width: "100%" }}
                    />
                    {this.state.original_title !== "Excluded words" && (
                      <Fragment>
                        <label className="padding-bottom-4 padding-top-40 display-block font-inter-500 color-969696 font-size-15">
                          {t("Test type")}
                        </label>
                        <div className="typology-checkboxes">
                          <div className="checkbox margin-right-25">
                            <Checkbox
                              className="consumer-checkbox"
                              name="consumer"
                              checked={this.state.formData.consumer}
                              onClick={this.onCheckConsumer}
                              disabled={!is_mode_edition}
                              sx={{
                                color: "#b4b4b4",
                                "&$checked": {
                                  color: "#3a79e6",
                                },
                                "&:hover": {
                                  backgroundColor: "transparent !important",
                                },
                              }}
                            />
                            <div
                              className={
                                !is_mode_edition
                                  ? "label-disabled typology-label"
                                  : "typology-label"
                              }
                            >
                              {t("Consumer")}
                            </div>
                          </div>
                          <div className="checkbox">
                            <Checkbox
                              className="lab_expert-checkbox"
                              name="lab / expert"
                              checked={this.state.formData.lab_expert}
                              onClick={this.onCheckExpert}
                              disabled={!is_mode_edition}
                              sx={{
                                color: "#b4b4b4",
                                "&$checked": {
                                  color: "#3a79e6",
                                },
                                "&:hover": {
                                  backgroundColor: "transparent !important",
                                },
                              }}
                            />
                            <div
                              className={
                                !is_mode_edition
                                  ? "label-disabled typology-label"
                                  : "typology-label"
                              }
                            >
                              {t("Lab / Expert")}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <label className="padding-top-40 display-block font-inter-500 color-969696 font-size-15 padding-bottom-4">
                      {t("Attributes")}
                    </label>
                    <div
                      className={`position-relative ${
                        is_mode_edition ? "" : "view-class-readonly"
                      }`}
                    >
                      {!is_mode_edition && (
                        <div className="view-class-plan-message">
                          <span>
                            <span className="icon plan-lock-key margin-right-5" />
                          </span>
                          <span className="text display-inline-block vertical-align-middle">
                            {messageNoEdition}
                          </span>
                        </div>
                      )}
                      <textarea
                        value={this.state.formData.keywords_txt}
                        rows={5}
                        cols={5}
                        onChange={this.handleChangeWords}
                        className="min-height-450 width-percent-100"
                        disabled={this.state.submitLoading || !is_mode_edition}
                      />
                    </div>
                    <div className="padding-bottom-36" />
                    {is_mode_edition && (
                      <div className="overflow-hidden">
                        {!this.state.submitLoading && (
                          <div className="float-right">
                            <Link
                              type="button"
                              className="button backgroundless"
                              to={PROJECT_EDIT(this.props.match.params.id)}
                            >
                              {t("Cancel")}
                            </Link>
                            <button
                              onClick={this.onSubmitModif}
                              disabled={!buttonIsEnabled}
                              className="margin-left-5 button backgroundless"
                            >
                              {t("Save class")}
                            </button>
                          </div>
                        )}
                        {this.state.submitLoading && (
                          <div
                            className="float-right"
                            style={{ padding: "2px 0" }}
                          >
                            <Loader />
                          </div>
                        )}
                      </div>
                    )}
                    {!is_mode_edition && (
                      <div className="overflow-hidden">
                        <div className="float-right">
                          <Link
                            type="button"
                            className="button backgroundless"
                            to={PROJECT_EDIT(this.props.match.params.id)}
                          >
                            {t("Close")}
                          </Link>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
                {this.state.projects.length > 0 && (
                  <div className="column-percent-35 hidden-900 padding-left-40">
                    <div className="font-size-24 font-inter-600 color-000 padding-bottom-40">
                      {libelleNbProjects}
                    </div>
                    {this.state.projects.map((item, i) => {
                      return (
                        <MiniProjectListItem
                          key={`list-item-${i}`}
                          item={item}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(EditClassView));
