import React from "react";
import { withTranslation } from "react-i18next";
import MediaListItem from "../../../components/list/MediaListItem";
import Loader from "../../../components/loader/Loader";
import ListOptSelect from "../../../components/listOptSelect/ListOptSelect";

class MediasList extends React.Component {
  constructor(props) {
    super(props);
    this.getUsedPercent = this.getUsedPercent.bind(this);
  }

  getUsedPercent() {
    const { item, totalObs, totalUsed } = this.props;
    let percent = 0;
    totalObs != 0 ? (percent = totalUsed / totalObs) : (percent = 0);
    return Number(percent).toLocaleString("en-US", {
      style: "percent",
      minimumFractionDigits: 0,
    });
  }

  render() {
    const { t, totalObs, totalUsed, quotasExceeded } = this.props;
    const project = this.props.project;
    const listItems = this.props.listItems;
    const inlineLoading = this.props.inlineLoading;
    const parentLoaderClass =
      listItems.length > 0
        ? "text-align-center padding-vertical-20"
        : "text-align-center padding-vertical-80";
    const isInprogress = listItems.some((item) => item.status == "INPROGRESS");

    return (
      <div className="central-content">
        <div className="display-flex margin-top-45 justify-content-flex-end display-block-responsive">
          <div className="position-relative">
            <div className="display-inline-block z-index-2">
              <ListOptSelect
                color="3a79e6"
                displayFamily="sorted_by"
                defaultActive={this.props.defaultSortedBy}
                position="right"
                onChange={this.props.onChangeSortList}
                lists={{
                  sorted_by: [
                    {
                      label: t("Sort by Last updated"),
                      value: "updated_at",
                      activeLabel: t("Last updated"),
                    },
                    {
                      label: t("Sort from A-Z"),
                      value: "name",
                      activeLabel: t("From A-Z"),
                    },
                    {
                      label: t("Sort by Average score"),
                      value: "average_score",
                      activeLabel: t("Average score"),
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="padding-top-24 content-list-media-items">
          {listItems.map((item, i) => {
            return (
              <div
                key={`list-item-div-${i}`}
                onClick={() => {
                  if (this.props.onClick) {
                    this.props.onClick(item);
                  }
                }}
              >
                <MediaListItem
                  key={`list-item-${i}`}
                  projectId={project.id}
                  selectDatasourceId={this.props.selectDatasourceId}
                  item={item}
                  onOpenDeleteModal={() => {
                    this.props.onOpenDeleteModal(item.id);
                  }}
                  onOpenReloadModal={() =>
                    this.props.onOpenReloadModal(item.id)
                  }
                  onOpenEditModal={() => this.props.onOpenEditModal(item.id)}
                  onExportCsv={(onThen) => {
                    this.props.onExportCsv(item.id, onThen);
                  }}
                  onOpenLink={() => {
                    this.props.onOpenLink(item.data);
                  }}
                  onLaunchAnalyse={this.props.onLaunchAnalyse}
                  quotasExceeded={quotasExceeded}
                />
              </div>
            );
          })}
        </div>
        {inlineLoading && (
          <div className={parentLoaderClass}>
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(MediasList);
