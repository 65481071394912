import HttpHelper from "../helpers/HttpHelper";
import { userService } from "./user.service";
import { saveAs } from "file-saver";

async function getProjects(formData, onSuccess, onError, signal = null) {
  const url = "/api/v1/projects";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError,
    signal
  );
}

async function getProject(
  projectId,
  onSuccess,
  onError,
  formData,
  signal = null
) {
  const url = "/api/v1/projects/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError,
    signal
  );
}

async function getProjectStatistics(
  projectId,
  onSuccess,
  onError,
  formData,
  signal = null
) {
  const url = "/api/v1/projects/" + projectId + "/statistics";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError,
    signal
  );
}

async function getProjectScore(
  projectId,
  formData,
  onSuccess,
  onError,
  signal = null
) {
  const url = "/api/v1/projects/" + projectId + "/score-mean-curve";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError,
    signal
  );
}

async function addDatasourceList(projectId, onSuccess, onError, signal) {
  const url = "/api/v1/projects/source-list/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError,
    signal
  );
}

async function getSearchableSources(project_id, onSuccess, onError) {
  const url = "/api/v1/scrape/services";
  const formData = { project_id: project_id };
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError
  );
}

async function getHypothesesSuggestions(
  projectId,
  formData,
  onSuccess,
  onError
) {
  const url = "/api/v1/projects/" + projectId + "/suggestions";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function getEstimatedScore(projectId, formData, onSuccess, onError) {
  const url = "/api/v1/projects/" + projectId + "/estimate-score";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function updateProject(projectId, formData, onSuccess, onError) {
  const url = "/api/v1/projects/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.PUT,
    formData,
    onSuccess,
    onError
  );
}

async function updateProjectName(projectId, formData, onSuccess, onError) {
  const url = "/api/v1/projects/" + projectId + "/edit";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.PUT,
    formData,
    onSuccess,
    onError
  );
}

async function updateProjectMainCharacteristics(
  projectId,
  formData,
  onSuccess,
  onError
) {
  const url = "/api/v1/projects/" + projectId + "/main-characteristics";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.PUT,
    formData,
    onSuccess,
    onError
  );
}

async function archiveProject(projectId, onSuccess, onError) {
  const url = "/api/v1/projects/" + projectId + "/archive";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.PUT,
    null,
    onSuccess,
    onError
  );
}

async function unArchiveProject(projectId, onSuccess, onError) {
  const url = "/api/v1/projects/" + projectId + "/unarchive";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.PUT,
    null,
    onSuccess,
    onError
  );
}

async function deleteProject(projectId, onSuccess, onError) {
  const url = "/api/v1/projects/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.DELETE,
    null,
    onSuccess,
    onError
  );
}

async function createProject(formData, onSuccess, onError) {
  const url = "/api/v1/projects";
  const handleSuccess = (data) => {
    HttpHelper.handleFunction(onSuccess, data);
    userService.updateCurrentUser();
  };
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    handleSuccess,
    onError
  );
}

async function followKeyword(projectId, formData, onSuccess, onError) {
  const url = "/api/v1/projects/follow/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function getExcelReport(
  projectId,
  filename,
  formData,
  onSuccess,
  onError
) {
  const requestOptions = {
    method: HttpHelper.method.GET,
    headers: HttpHelper.authHeader({ "Content-Type": "text/csv" }),
    responseType: "blob",
  };

  const url = HttpHelper.getApiUrl(
    `/api/v1/report/excel-report/${projectId}`,
    HttpHelper.method.GET,
    formData
  );

  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedFileResponse)
    .then((response) => response.blob())
    .then((blob) => {
      if (blob) {
        saveAs(blob, filename);
        HttpHelper.handleFunction(onSuccess);
        return blob;
      }
    })
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
}

async function getDocxReport(
  projectId,
  filename,
  formData,
  onSuccess,
  onError
) {
  const requestOptions = {
    method: HttpHelper.method.GET,
    headers: HttpHelper.authHeader({ "Content-Type": "text/csv" }),
    responseType: "blob",
  };

  const url = HttpHelper.getApiUrl(
    `/api/v1/projects/${projectId}/report_docx`,
    HttpHelper.method.GET,
    formData
  );

  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedFileResponse)
    .then((response) => response.blob())
    .then((blob) => {
      if (blob) {
        saveAs(blob, filename);
        HttpHelper.handleFunction(onSuccess);
        return blob;
      }
    })
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
}

// Handle PDF / PPT Report download
async function getPPDFPPTReport(
  projectId,
  datasourceId,
  exportType,
  filename,
  onSuccess,
  onError
) {
  const requestOptions = {
    method: HttpHelper.method.GET,
    headers: HttpHelper.authHeader({ "Content-Type": "text/csv" }),
    responseType: "blob",
  };

  var url = HttpHelper.getApiUrl(
    `/api/v1/projects/${projectId}/report/${exportType}`,
    HttpHelper.method.GET,
    { datasource_id: datasourceId }
  );

  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedFileResponse)
    .then((response) => response.blob())
    .then((blob) => {
      if (blob) {
        saveAs(blob, filename);
        HttpHelper.handleFunction(onSuccess);
        return blob;
      }
    })
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
}

export const projectService = {
  get: {
    getAll: getProjects,
    get: getProject,
    getStatistics: getProjectStatistics,
    getScore: getProjectScore,
    addDatasourceList: addDatasourceList,
    getSearchableSources: getSearchableSources,
    getExcelReport: getExcelReport,
    getDocxReport: getDocxReport,
    getPPDFPPTReport: getPPDFPPTReport,
  },
  put: {
    archive: archiveProject,
    unarchive: unArchiveProject,
    update: updateProject,
    update_name: updateProjectName,
    update_main_characteristics: updateProjectMainCharacteristics,
  },
  delete: {
    delete: deleteProject,
  },
  post: {
    create: createProject,
    followKeyword: followKeyword,
    estimate_score: getEstimatedScore,
    get_hypotheses_suggestions: getHypothesesSuggestions,
  },
};
