import React, { Fragment } from "react";
import { withTranslation, Trans } from "react-i18next";
import Loader from "../../../../components/loader/Loader";
import Modal from "../../../../components/modal/Modal";
import TextField from "../../../../components/form/field/TextField";
import SelectField from "../../../../components/form/field/SelectField";
import { USER_ROLES } from "../../../../app/constants";
import RadioGroupField from "../../../../components/form/field/RadioGroupField";
import StringHelper from "../../../../app/helpers/StringHelper";
import Link from "../../../../components/Link";
import { ROUTES } from "../../../../app/routing";
import { Box } from "@mui/material";
import STypography from "../../../../components/SMui/STypography";
import add_user_icon from "../../../../res/images/plan-icons/add-user-orange.svg";
class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    let teamsOptions = [];
    if (props.teams) {
      props.teams.forEach((element) => {
        teamsOptions.push({
          label: element.title,
          value: element.id,
          target: {
            name: "team_id",
            value: element.id,
          },
        });
      });
    }
    const rolesOptions = [
      {
        label: t("Administrator"),
        value: USER_ROLES.ADMIN,
      },
      {
        label: t("User"),
        value: USER_ROLES.USER,
      },
    ];
    const team_id = teamsOptions.length > 0 ? teamsOptions[0].value : null;
    this.state = {
      formData: {
        email: "",
        team_id: team_id,
        role: USER_ROLES.USER,
      },
      errors: null,
      teamsOptions: teamsOptions,
      rolesOptions: rolesOptions,
    };
    this.modalRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.getMessageErrorPlan = this.getMessageErrorPlan.bind(this);
  }

  onCancelClick() {
    if (this.modalRef && this.modalRef.current) {
      this.modalRef.current.onModalCloseClick();
    }
  }

  getErrors(event) {
    const { t } = this.props;
    let errors = this.state.errors ? Object.assign({}, this.state.errors) : {};
    if (event.target.name === "email") {
      if (!StringHelper.isEmailValid(event.target.value)) {
        errors = { email: t("Please enter a valid email") };
      } else {
        delete errors.email;
      }
    }
    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleChange(event) {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    let errors = this.getErrors(event);
    this.setState({
      formData: formData,
      errors: errors,
    });
  }

  getErrorMessage(target) {
    const { error } = this.props;
    if (this.state.errors && this.state.errors.hasOwnProperty(target)) {
      return this.state.errors[target];
    }
    if (error && error.hasOwnProperty(target)) {
      return error[target];
    }
    return null;
  }

  formIsValid() {
    if (this.state.formData.email && !this.state.errors) {
      return true;
    }
    return false;
  }

  getMessageErrorPlan() {
    const { currentPlan } = this.props;
    return (
      <Trans i18nKey="error_plan_add_user">
        You are on the {{ currentPlan }} plan. Go to{" "}
        <Link
          to={ROUTES.PLAN_CHOOSE}
          className="text-decoration-underline color-000"
        >
          upgrade your plan
        </Link>{" "}
        to invite more members to your organization.
      </Trans>
    );
  }

  render() {
    const { t, loading } = this.props;
    const buttonIsEnabled = this.formIsValid() && !this.props.errorPlan;
    return (
      <Modal
        ref={this.modalRef}
        display={this.props.display}
        onClose={this.props.onClose}
      >
        <div className="font-size-30 font-inter-700 color-000 padding-bottom-40">
          {t("Create User")}
        </div>
        <form>
          <div className="padding-bottom-36">
            <TextField
              type="text"
              label={t("Email")}
              name="email"
              defaultValue={this.state.formData.email}
              disabled={loading}
              onChange={this.handleChange}
              errorMessage={this.getErrorMessage("email")}
            />
            <SelectField
              label={t("Assign to team")}
              name="team_id"
              defaultValue={
                this.state.teamsOptions.length > 0
                  ? this.state.teamsOptions[0]
                  : null
              }
              options={this.state.teamsOptions}
              onChange={this.handleChange}
              errorMessage={this.getErrorMessage("team_id")}
            />
            <RadioGroupField
              label={t("Access")}
              name="role"
              defaultValue={this.state.formData.role}
              options={this.state.rolesOptions}
              onChange={this.handleChange}
              errorMessage={this.getErrorMessage("role")}
            />
            {this.props.errorPlan && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "6px 16px",
                  alignItems: "center",
                  gap: "16px",
                  borderRadius: "4px",
                  border: "1px solidrgba(226, 235, 251, 0.30)",
                  background: "#FDE7D4",
                }}
              >
                <img src={add_user_icon} alt="add user icons" />
                <STypography
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  {
                    <Trans i18nKey="out_of_seat">
                      Oops! You're out of seats! It looks like all your
                      available seats are already in use. You can't add more
                      users right now."{" "}
                      <Link
                        to={ROUTES.SETTINGS_PLAN_AND_BILLING_BASE}
                        className="text-decoration-underline color-3A79E6"
                      >
                        Unlock More Seat Now!
                      </Link>
                    </Trans>
                  }
                </STypography>
              </Box>
            )}
          </div>

          <div className="overflow-hidden">
            {!this.props.loading && (
              <Fragment>
                <div className="float-right">
                  <button
                    className="button backgroundless"
                    type="button"
                    onClick={this.onCancelClick}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="button backgroundless margin-left-5"
                    disabled={!buttonIsEnabled}
                    onClick={() => {
                      this.props.onCreateItem(this.state.formData);
                    }}
                  >
                    {t("Create")}
                  </button>
                </div>
                {!!this.props.error && (
                  <div className="color-e04f77 font-size-15 line-height-34">
                    <span className="display-inline-block line-height-1-2">
                      {this.props.error}
                    </span>
                  </div>
                )}
              </Fragment>
            )}
            {this.props.loading && (
              <div className="float-right" style={{ padding: "2px 0" }}>
                <Loader />
              </div>
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

export default withTranslation()(CreateUserModal);
