import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  ACCOUNT_PLAN,
  globalConstants,
  PLAN_JSON_LOGIC_KEYS,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";

import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import Footer from "../../components/footer/Footer";
import { projectService } from "../../app/services/project.service";
import { mediaService } from "../../app/services/media.service";
import Link from "../../components/Link";
import Loader from "../../components/loader/Loader";
import { PROJECT_ADD_MEDIA, PROJECT_DETAIL, ROUTES } from "../../app/routing";

import "./addMedia.css";
import HttpHelper from "../../app/helpers/HttpHelper";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import MediaHelper from "../../app/helpers/MediaHelper";
import { SessionStorageService } from "../../app/services/storage.service";
import MediaHeader from "./elements/MediaHeader";
import TextField from "../../components/form/field/TextField";
import RadioGroupField from "../../components/form/field/RadioGroupField";
import illustration from "../../res/images/illu-excel.svg";
import simple from "../../res/images/icons/pic-simple.svg";
import test from "../../res/images/icons/pic-test.svg";
import addDataSource2Img from "./../../res/images/AddDataSource2.png";
import addDataSource3Img from "./../../res/images/AddDataSource3.png";
import Typography from "@mui/joy/Typography";
import Box from "@mui/system/Box";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import { DatasourceWarning } from "./elements/DatasourceWarning";

class AddMediaConfirmationView extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutUrl = null;
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const title = urlParams.title || "";
    const index = parseInt(urlParams.index) || "";
    const product_id = urlParams.productId || "";
    const type = urlParams.type || MediaHelper.type.YOUTUBE;
    const filepath = urlParams.filepath || "";
    const selectedSheet = urlParams.selectedSheet || null;
    const productSourcesId = urlParams.productSourcesId || null;
    const selectedColumnName = urlParams.selectedColumnName || "";
    const removeHeader = urlParams.removeHeader == "1" ? true : false;
    const referrer =
      props.location.state && props.location.state.referrer
        ? props.location.state.referrer
        : null;
    const analyseParams =
      props.location.state && props.location.state.analyseParams
        ? props.location.state.analyseParams
        : null;
    const preview =
      props.location.state && props.location.state.preview
        ? props.location.state.preview
        : null;
    this.state = {
      projectId: props.match.params.id,
      project: null,
      referrer: referrer,
      analyseParams: analyseParams,
      preview: preview,
      isLoaded: false,
      formData: {
        title: title,
        product_id: product_id,
        type: type,
        filepath: filepath,
        sheet_name: selectedSheet,
        column_index: index,
        remove_header: removeHeader,
        analyseParams: analyseParams,
        typology: "CONSUMER",
        number_of_reviews: preview ? preview.responseReview : 0,
      },
      typology: "Consumer",
      selectedColumnName: selectedColumnName,
      mediaTitleError: null,
      submitLoading: false,
      totalReviews: null,
      maxReviews: null,
      planErrorType: null,
      planErrorStatus: null,
      currentPlan: null,
      urlData: null,
      urlLoading: false,
      displayInformationUrl: false,
      youtubeUniqueLanguage: null,
      txtFileUploadError: null,
      txtFileUploadProgress: null,
      txtFileInputKey: "upload-field-confirmation",
      txtFileSize: null,
      languageInfos: urlParams.default_language || null,
      productSourcesId: productSourcesId,
    };
    this.fetchProject = this.fetchProject.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.onSubmitAddMedia = this.onSubmitAddMedia.bind(this);
    this.submitIsEnabled = this.submitIsEnabled.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.onSubmitMakeRequest = this.onSubmitMakeRequest.bind(this);
    this.getFileFromEvent = this.getFileFromEvent.bind(this);
    this.onChangeTest = this.onChangeTest.bind(this);
    this.getPlanJsonData = this.getPlanJsonData.bind(this);
    this.getPlanErrorMessage = this.getPlanErrorMessage.bind(this);

    if (!SessionStorageService.get("update_media_referrer") && referrer) {
      SessionStorageService.set("update_media_referrer", referrer);
    }

    if (!this.state.formData.product_id && !this.state.formData.filepath) {
      this.props.history.push(PROJECT_ADD_MEDIA(this.state.projectId));
    }
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProject();
    if (this.state.preview) {
      this.getPlanJsonData(this.state.preview.responseReview);
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          isLoaded: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "minimal" }
    );
  }

  getPlanJsonData(number_of_reviews = 0) {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
      {
        project_id: this.state.projectId,
        other_datas: { new_datasource_nb_reviews: number_of_reviews }, // TODO: set real nb_reviews
      },
      (response) => {
        const max = response.data.max_reviews_for_account;
        const total =
          response.current_data.current_reviews_for_account +
          response.current_data.new_datasource_nb_reviews;

        if (response.status !== "success") {
          this.setState({
            planErrorType: PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
            planErrorStatus: response.status,
            totalReviews: total,
            maxReviews: max,
            currentPlan: response.data.plan,
          });
        }
      },
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  getPlanErrorMessage() {
    if (!this.state.planErrorType) {
      return null;
    }

    if (this.state.planErrorType === PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA) {
      return (
        <Box sx={{ mt: "20px" }}>
          <DatasourceWarning
            {...this.props}
            isEnterprise={this.state.currentPlan == ACCOUNT_PLAN.ENTERPRISE}
            status={this.state.planErrorStatus}
            totalReviews={this.state.totalReviews}
            maxReviews={this.state.maxReviews}
          />
        </Box>
      );
    }
  }

  handleChangeTitle(event) {
    const { t } = this.props;
    let newFormData = Object.assign({}, this.state.formData);
    let urlData = Object.assign({}, this.state.urlData);
    newFormData.title = event.target.value;
    urlData.title = event.target.value;
    const displayInformationUrl = newFormData.title.length > 0;
    const mediaTitleError =
      newFormData.title.length > 256
        ? t("Title must be less than 256 characters.")
        : null;
    this.setState({
      formData: newFormData,
      urlData: urlData,
      mediaTitleError: mediaTitleError,
      displayInformationUrl: displayInformationUrl,
    });
  }

  /* Start TXT File part */

  getFileFromEvent(event) {
    let files = [];
    if (event.dataTransfer && event.dataTransfer.files) {
      files = event.dataTransfer.files;
    } else if (event.target && event.target.files) {
      files = event.target.files;
    }
    if (files.length === 0) {
      return null;
    }

    let txtFiles = [];
    for (const file of files) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (extension === "txt") {
        txtFiles.push(file);
      }
    }

    if (txtFiles.length === 0) {
      return null;
    }

    return txtFiles[txtFiles.length - 1];
  }

  displayToastErrorMessage(error) {
    if (!this._ismounted) {
      return;
    }
    this.setState({ submitLoading: false });
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
  }

  onSubmitAddMedia(e) {
    e.preventDefault();
    this.setState({ submitLoading: true });

    const formData = Object.assign(this.state.formData, {
      language: this.state.languageInfos,
      product_source_id: this.state.productSourcesId,
    });
    this.onSubmitMakeRequest(formData);
  }

  onSubmitMakeRequest(formData) {
    this.setState({ planErrorType: null });
    mediaService.post.create(
      this.state.projectId,
      formData,
      (response3) => {
        this.props.addToast(
          'Data source "' + response3.title + '" successfully added',
          { type: "success", autoDismiss: true }
        );
        this.setState({ submitLoading: false });
        this.props.history.push(PROJECT_DETAIL(this.state.projectId));
        sessionStorage.removeItem("selectedSheet");
        sessionStorage.removeItem("selectedCharacteristics");
        sessionStorage.removeItem("selectedFields");
        sessionStorage.removeItem("selectedReviews");
        sessionStorage.removeItem("columnsOptions");
        IntercomHelper.trackEvent("Datasource_added", {
          name: response3.title,
          project_id: this.state.projectId,
        });
      },
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  submitIsEnabled() {
    if (
      this.state.mediaTitleError ||
      this.state.formData.title.length === 0 ||
      this.state.formData.typology === null ||
      (this.state.planErrorType && this.state.planErrorStatus != "warning")
    ) {
      return false;
    }

    return true;
  }

  onChangeTest(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        typology: event.target.value,
      },
      typology: event.target.getAttribute("label"),
    });
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Add data source")} - ${
      globalConstants.WEBSITE_TITLE
    }`;

    const messageErrorPlan = this.getPlanErrorMessage();
    const buttonIsEnabled = this.submitIsEnabled();
    const project = this.state.project;

    let total = 0;

    for (const key in this.state.preview) {
      if (this.state.preview[key] !== undefined) {
        total += this.state.preview[key];
      }
    }

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <MediaHeader
            history={this.props.history}
            title={t("Add data source")}
          />
          <div className="display-flex justify-content-center align-items-flex-start">
            <div className="width-520 padding-top-50 padding-bottom-20">
              <div
                className="central-content-768 selectSheetView"
                style={{ width: "100%" }}
              >
                <form>
                  {project && (
                    <div className="padding-bottom-4">
                      <Link
                        to={PROJECT_DETAIL(project.id)}
                        className="font-size-18 link-grey transition-color text-break-line"
                      >
                        {project.name}
                      </Link>
                    </div>
                  )}

                  <div className="font-size-32 font-inter-700 color-000 padding-bottom-50">
                    {t("Confirm Your Import")}
                  </div>

                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      letterSpacing: "0.15px",
                      mb: "15px",
                    }}
                  >
                    {t("Datasource name")}
                  </Typography>

                  <TextField
                    hideLabel={true}
                    type="text"
                    name="title"
                    value={this.state.formData.title}
                    onChange={this.handleChangeTitle}
                    errorMessage={this.state.mediaTitleError}
                    disabled={this.state.submitLoading}
                    maxLength={256}
                    style={{ height: "50px", borderRadius: 0 }}
                  />

                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      letterSpacing: "0.15px",
                      mt: "5px",
                      mb: "8px",
                    }}
                  >
                    {t("Test type")}
                  </Typography>
                  <div className="container-tests">
                    <RadioGroupField
                      name="choose_your_test"
                      hideLabel={true}
                      options={[
                        { value: "CONSUMER", label: t("Consumer") },
                        { value: "LAB_EXPERT", label: t("Lab / Expert") },
                      ]}
                      onChange={this.onChangeTest}
                      defaultValue={"CONSUMER"}
                    />
                  </div>

                  {!!messageErrorPlan && messageErrorPlan}

                  <div className="overflow-hidden margin-top-50">
                    {!this.state.submitLoading && (
                      <div className="float-right">
                        <button
                          type="button"
                          className="button backgroundless color-6d9eee"
                          onClick={this.props.history.goBack}
                        >
                          {t("Back")}
                        </button>
                        <button
                          onClick={this.onSubmitAddMedia}
                          disabled={!buttonIsEnabled}
                          className="margin-left-5 button backgroundless color-3a79e6"
                        >
                          {t("Add data source")}
                        </button>
                      </div>
                    )}
                    {this.state.submitLoading && (
                      <div className="float-right" style={{ padding: "2px 0" }}>
                        <Loader />
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="width-60" />

            <div className="width-520 padding-top-50 padding-bottom-20 display-only-large-screen">
              {this.state.formData.type !== "SHEET_FILE" && (
                <Box
                  sx={{
                    backgroundImage: `url(${addDataSource2Img})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "545px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    width="288px"
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      letterSpacing: "0.15px",
                      mt: "220px",
                      textAlign: "center",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textWrap: "nowrap",
                    }}
                  >
                    {this.state.formData.title}
                  </Typography>
                </Box>
              )}

              {this.state.formData.type === "SHEET_FILE" && (
                <Box
                  sx={{
                    backgroundImage: `url(${addDataSource3Img})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "545px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    width="288px"
                    sx={{
                      mt: "220px",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      width="288px"
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                    >
                      {this.state.formData.title}
                    </Typography>
                    <Typography
                      width="288px"
                      sx={{
                        mt: "8px",
                        color: "#7F7F7F",
                        fontFamily: "Inter",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "150%",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {this.state.typology} • {total} answer
                      {total > 1 ? "s" : ""}
                    </Typography>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mt: "17px" }}
                    >
                      <img src={simple} alt="#" />
                      <Typography
                        sx={{
                          ml: "9px",
                          fontFamily: "Inter",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Profile field •
                      </Typography>
                      <Typography
                        sx={{
                          color: "#0E004B",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                        }}
                      >
                        {this.state.preview?.responseField} answer
                        {this.state.preview?.responseField > 1 ? "s" : ""}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mt: "14px" }}
                    >
                      <img src={test} alt="#" />
                      <Typography
                        sx={{
                          ml: "9px",
                          fontFamily: "Inter",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Review / Feedback •
                      </Typography>

                      <Typography
                        sx={{
                          color: "#0E004B",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                        }}
                      >
                        {this.state.preview?.responseReview} answer
                        {this.state.preview?.responseReview > 1 ? "s" : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={false}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(
  connect(mapStateToProps)(AddMediaConfirmationView)
);
