import UserHelper from "../../../app/helpers/UserHelper";

const authProvider = {
  checkAuth: () => {
    const user = UserHelper.getUser();
    return user ? Promise.resolve(user) : Promise.reject(user);
  },
  getPermissions: () => {
    const user = UserHelper.getUser();
    const userIsSensia = UserHelper.userIsSensia();
    return userIsSensia ? Promise.resolve(user) : Promise.reject(user);
  },
  checkError: () => {},
};

export default authProvider;
