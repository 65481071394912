import { globalConstants } from "../constants";
import HttpHelper from "../helpers/HttpHelper";

async function getPaymentMethod(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/billing/paymentMethod/",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function setupIntent(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/billing/setup-intent/",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function savePaymentMethod(paymentMethod, onSuccess, onError) {
  const formData = { paymentMethod: paymentMethod };
  return HttpHelper.authApiFetch(
    "/api/v1/billing/savePaymentMethod/",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function createSubscribtion(
  price_id,
  quantity,
  return_url,
  onSuccess,
  onError
) {
  const formData = {
    price_id: price_id,
    success_url: return_url,
    return_url: return_url,
    quantity: quantity,
  };
  return HttpHelper.authApiFetch(
    "/api/v1/billing/subscribtion",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function updateSubscribtion(
  priceId,
  subscriptionId,
  quantity,
  onSuccess,
  onError
) {
  const formData = {
    price_id: priceId,
    subscription_id: subscriptionId,
    quantity: quantity,
    return_url: `${globalConstants.CLIENT_URL}/settings/plan-and-billing`,
    confirm_url: `${globalConstants.CLIENT_URL}/subscription-success`,
  };
  return HttpHelper.authApiFetch(
    "/api/v1/billing/update-subscribtion",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function cancelSubscribtion(subscriptionId, onSuccess, onError) {
  const formData = {
    subscription_id: subscriptionId,
    return_url: `${globalConstants.CLIENT_URL}/settings/plan-and-billing`,
    confirm_url: `${globalConstants.CLIENT_URL}/subscription-success?cancel=true`,
  };
  return HttpHelper.authApiFetch(
    "/api/v1/billing/cancel-subscribtion",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function downgradeSubscribtion(plan_key, onSuccess, onError) {
  const formData = {
    plan_key: plan_key,
  };
  return HttpHelper.authApiFetch(
    "/api/v1/billing/downgrade-subscribtion",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function setDefaultPaymentMethod(cardId, onSuccess, onError) {
  const formData = {
    paymentMethodId: cardId,
  };
  return HttpHelper.authApiFetch(
    "/api/v1/billing/setDefaultPaymentMethod/",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function deletePaymentMethod(cardId, onSuccess, onError) {
  const formData = {
    paymentMethodId: cardId,
  };
  return HttpHelper.authApiFetch(
    "/api/v1/billing/deletePaymentMethod/",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function getPlan(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/billing/plan",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getPreviewChange(priceId, onSuccess, onError) {
  const formData = {
    new_price_id: priceId,
  };
  return HttpHelper.authApiFetch(
    "/api/v1/billing/preview-change-subscribtion",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

export const billingService = {
  getPaymentMethod,
  setupIntent,
  savePaymentMethod,
  createSubscribtion,
  updateSubscribtion,
  cancelSubscribtion,
  downgradeSubscribtion,
  setDefaultPaymentMethod,
  deletePaymentMethod,
  getPlan,
  getPreviewChange,
};
