import HttpHelper from "../helpers/HttpHelper";
import IntercomHelper from "../helpers/IntercomHelper";
import SignupHelper from "../helpers/SignupHelper";
import UserHelper from "../helpers/UserHelper";

async function login(formData, onSuccess, onError) {
  const handleSuccess = (data) => {
    if (onSuccess) {
      if (!data.user?.id) {
        HttpHelper.handleFunction(onError, data);
        return data;
      }
      UserHelper.setUser(data.user);
      onSuccess(data);
      return data;
    }
  };
  const url = "/api/v1/user/login";
  return HttpHelper.apiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    handleSuccess,
    onError
  );
}

function updateCurrentUser(onSuccess, onError) {
  const url = "/api/v1/user/current";
  const handleSuccess = (user) => {
    UserHelper.updateUser(user);
    HttpHelper.handleFunction(onSuccess, user);
  };
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    handleSuccess,
    onError
  );
}

function signup(formData, onSuccess, onError) {
  const url = "/api/v1/user/signup";
  return HttpHelper.apiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

function logout() {
  // remove user from local storage to log user out
  const user = UserHelper.getUser();
  IntercomHelper.shutdownIntercom();

  if (!user) {
    return;
  }
  const requestOptions = {
    method: HttpHelper.method.POST,
    headers: HttpHelper.authHeader({ "Content-Type": "application/json" }),
  };
  UserHelper.deleteUser();
  SignupHelper.deleteUser();
  return fetch(HttpHelper.getApiUrl("/api/v1/user/logout"), requestOptions)
    .then(HttpHelper.handleResponse)
    .then(
      (result) => {
        return result;
      },
      (error) => {
        //console.error('error', error)
      }
    );
}

function createUser(formData, onSuccess, onError) {
  const url = "/api/v1/user/passwd";

  const handleSuccess = (data) => {
    if (onSuccess) {
      if (!data.user?.id) {
        HttpHelper.handleFunction(onError, data);
        return data;
      }
      UserHelper.setUser(data.user);
      onSuccess(data);
      return data;
    }
  };

  return HttpHelper.apiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    handleSuccess,
    onError
  );
}

function passwdSetNew(formData, onSuccess, onError) {
  const url = "/api/v1/user/new-passwd";

  const handleSuccess = (data) => {
    if (onSuccess) {
      if (!data.user?.id) {
        HttpHelper.handleFunction(onError, data);
        return data;
      }
      UserHelper.setUser(data.user);
      onSuccess(data);
      return data;
    }
  };

  return HttpHelper.apiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    handleSuccess,
    onError
  );
}

function lostPasswd(formData, onSuccess, onError) {
  return HttpHelper.apiFetch(
    "/api/v1/user/lost-passwd",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

function resentInvitationEmail(formData, onSuccess, onError) {
  const url = "/api/v1/user/invitation/email";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

function skipUserGuide(formData, onSuccess, onError) {
  const url = "/api/v1/user/guide/skip";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

function userGuideCompletionInfos(onSuccess, onError) {
  const url = "/api/v1/user/guide";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

function invitation(formData, onSuccess, onError) {
  const url = "/api/v1/user/invitation";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

function registerInvitation(formData, onSuccess, onError) {
  return HttpHelper.apiFetch(
    "/api/v1/user/register-invitation",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

function getTeams(formData, onSuccess, onError) {
  const url = "/api/v1/user/team";
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

async function getAccountUsers(account_id, onSuccess, onError) {
  const url = "/api/v1/user/account-users/" + account_id;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function checkTokenInvitation(token, onSuccess, onError) {
  const url = "/api/v1/user/check-token-invitation/" + token;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

function edit(formData, onSuccess, onError) {
  const handleSuccess = (data) => {
    HttpHelper.handleFunction(onSuccess, data);
    userService.updateCurrentUser();
  };
  return HttpHelper.authApiFetch(
    "/api/v1/user/edit-user",
    HttpHelper.method.POST,
    formData,
    handleSuccess,
    onError
  );
}

function editProfil(formData, onSuccess, onError) {
  const requestOptions = {
    method: HttpHelper.method.POST,
    headers: HttpHelper.authHeader(),
    body: formData,
  };
  return fetch(HttpHelper.getApiUrl("/api/v1/user/edit-profil"), requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (response) => {
        HttpHelper.handleFunction(onSuccess, response);
        return response;
      },
      (error) => {
        HttpHelper.handleFunction(onError, error);
      }
    );
}

async function deleteUser(user_id, account_id, onSuccess, onError) {
  const url = "/api/v1/user/delete-user/" + user_id + "/" + account_id;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.DELETE,
    null,
    onSuccess,
    onError
  );
}

async function GetProfilUserData(user_id, onSuccess, onError) {
  const url = "/api/v1/user/data-profil/" + user_id;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function checkValidEmailToken(token, onSuccess, onError) {
  const url = "/api/v1/user/check-email-token/" + token;
  return HttpHelper.apiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function createAccount(user_id, formData, onSuccess, onError) {
  const handleSuccess = (data) => {
    if (data.user) {
      UserHelper.setUser(data.user);
      UserHelper.updateCurrentAccount(data.account);
    }
    onSuccess(data);
    return data;
  };
  const url = "/api/v1/user/create-account/" + user_id;
  return HttpHelper.apiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    handleSuccess,
    onError
  );
}

export const userService = {
  login,
  logout,
  signup,
  createUser,
  passwdSetNew,
  lostPasswd,
  invitation,
  registerInvitation,
  getTeams,
  getAccountUsers,
  checkTokenInvitation,
  edit,
  deleteUser,
  GetProfilUserData,
  editProfil,
  checkValidEmailToken,
  updateCurrentUser,
  resentInvitationEmail,
  skipUserGuide,
  userGuideCompletionInfos,
  createAccount,
};
