/**
 * IntercomHelper.js
 * More information can be retrieved from: https://developers.intercom.com/installing-intercom/web/methods/
 */
class IntercomHelper {}

/**
 * Boot Intercom:
 * If you'd like to control when Intercom is loaded, you can use the 'boot' method. This is useful in situations like a one-page Javascript 
 * based application where the user may not be logged in when the page loads. You call this method with the standard intercomSettings object.
 */
IntercomHelper.bootIntercom = () => {
    const appId = window.APP_ID;
    if (appId) {
        window.Intercom('boot', {
            app_id: appId,
        });
    } else {
        console.error("APP_ID is not defined in the global scope!");
    }
}

/**
 * Update Intercom:
 * Calling the update method with some new user data will trigger the JavaScript to look for new messages that should be displayed to the current 
 * user (the one whose details are in the window.intercomSettings variable) and show them if they exist.
 * 
 * Calling the update method with a JSON object of user details will update those fields on the current user in addition to logging an impression 
 * at the current URL and looking for new messages for the user.
 * 
 */
IntercomHelper.updateIntercom = () => {
    window.Intercom('update');
}

IntercomHelper.updateIntercomUser = (user, signup=false) => {

    //var companies = [];
    var companies = user.accounts?.map((company) => {
        return {
            company_id: company.id,
            name: company.title,
            plan: company.plan_key,
        }
    });

    var status = signup ? 'Pending' : 'Active';

    var intercom_user = {
      email: user.email,
      name: user.firstname + " " + user.lastname,
      user_id: user.id,
      phone: user.phone_number,
      created_at: new Date(user.created_at).getTime() / 1000,
      is_member: user.nb_account > 1,
      status: status,
      "User job": user.user_job,
      "Company size": user.company_size,
      "User Expectation": user.user_expectation,
      "User discovery source": user.user_discovery_source,
      companies: companies,
    };

    window.Intercom('update', intercom_user);
    console.log('Intercom user updated', intercom_user);
}

/**
 * Change Intercom User status to DELETE
 * @param {*} userid 
 */
IntercomHelper.deleteIntercomUser = (userid) => {
    window.Intercom('update', {
        user_id: userid,
        "status": "DELETE",
    });
    console.log('Intercom user delete', userid);
}

/**
 * Shutdown Intercom:
 * If you'd like to make sure the Intercom Messenger is not available to your users, you can call the 'shutdown' method.
 * Call the Intercom shutdown method to clear your users’ conversations anytime they logout of your application.
 */
IntercomHelper.shutdownIntercom = () => {
    window.Intercom('shutdown');
 }  
 

/**
 * Show Intercom:
 * This will show the Messenger. If there are no new conversations, it will open to the Messenger Home. If there are, it will open with the message list.
 */
IntercomHelper.showIntercom = () => {
    window.Intercom('show');
}

/**
 * Hide Intercom:
 * This will hide the main Messenger panel if it is open. It will not hide the Messenger Launcher.
 */
IntercomHelper.hideIntercom = () => {
    window.Intercom('hide');
}   

/**
 * You can submit an event using the trackEvent method. This will associate the event with the currently logged in user and send it to Intercom. 
 * The final parameter is a map that can be used to send optional metadata about the event.
 * 
 * You can also add custom information to events in the form of event metadata, which can be included in event based messages to your customers.
 * 
 * @param {*} event 
 * @param {*} metadata 
 */
IntercomHelper.trackEvent = (event, metadata) => {
    window.Intercom('trackEvent', event, metadata);
}



export default IntercomHelper;