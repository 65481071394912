import React from "react";

import "./res/style/hush/hush.css";
import "./res/style/hush/puppy.css";
import "./res/style/style.css";

import { createRoot } from "react-dom/client";

import "./app/i18n";

import App from "./app/App";
import { store } from "./app/helpers";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { globalConstants } from "./app/constants";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./app/authConfig";
import { AccountDataManagerProvider } from "./dataManagers/accountDataManager";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={globalConstants.GOOGLE_CLIENT_ID}>
      <MsalProvider instance={msalInstance}>
        <AccountDataManagerProvider>
          <App />
        </AccountDataManagerProvider>
      </MsalProvider>
    </GoogleOAuthProvider>
  </Provider>
);
