import "../plan.css";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { getAccountPlan, globalConstants } from "../../../app/constants";
import { connect } from "react-redux";
import LoadingView from "../../loading/LoadingView";
import { withTranslation, Trans } from "react-i18next";
import PlanHeader from "../elements/PlanHeader";
import { SessionStorageService } from "../../../app/services/storage.service";
import { accountService } from "../../../app/services/account.service";
import UserHelper from "../../../app/helpers/UserHelper";
import HttpHelper from "../../../app/helpers/HttpHelper";
import { Box } from "@mui/material";
import lock_key from "../../../res/images/icons/lock-key.svg";
import STypography from "../../../components/SMui/STypography";
import { ROUTES } from "../../../app/routing";

class ContactYourAdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      inlineLoading: false,
      account: null,
      currentPlan: null,
    };

    this.fetchInitial = this.fetchInitial.bind(this);

    const referrer =
      props.location.state && props.location.state.referrer
        ? props.location.state.referrer
        : null;
    if (!SessionStorageService.get("update_plan_referrer") && referrer) {
      SessionStorageService.set("update_plan_referrer", referrer);
    }
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    const isAdmin = UserHelper.isAdmin();
    if (isAdmin) {
      this.props.history.push(ROUTES.PLAN_CHOOSE);
    }
    accountService.getAccountData(
      (response) => {
        const currentPlan = getAccountPlan(response.plan_key);
        this.setState({
          isLoaded: true,
          inlineLoading: false,
          account: response,
          currentPlan: currentPlan,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
          inlineLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Contact your administrator")} - ${
      globalConstants.WEBSITE_TITLE
    }`;
    if (this.state.isLoaded) {
      const contactLink = `mailto:${globalConstants.CONTACT_EMAIL}?subject=${t(
        "Sensia - upgrade plan"
      )}`;
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <PlanHeader history={this.props.history} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "85vh",
              p: "20px",
            }}
          >
            <img src={lock_key} alt="lock_key" style={{ width: "40px" }} />
            <STypography
              sx={{
                mt: "10px",
                fontSize: "30px",
                fontWeight: 700,
              }}
            >
              {
                <Trans i18nKey="your_sensia_workspace_is_on_the_x_plan">
                  Your Sensia workspace is on the{" "}
                  <span>{this.state.currentPlan}</span> plan
                </Trans>
              }
            </STypography>
            <STypography
              sx={{
                mt: "20px",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "21px",
              }}
            >
              <Trans i18nKey="you_need_specia_privileges_to_change_the_subscription_please_contact_your_administrator">
                You need special privileges to change the subscription. Please
                reach out to the{" "}
                <a href={contactLink} className="link-blue">
                  Sensia team
                </a>{" "}
                with any questions.
              </Trans>
            </STypography>
          </Box>
        </Fragment>
      );
    }
    return <LoadingView title={page_title} />;
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(
  connect(mapStateToProps)(ContactYourAdminView)
);
