import React from "react";
import { withTranslation } from "react-i18next";
import imgEmptyState from "../../../res/images/add-datasource-empty-state.svg";
import "./EmptyState.css";
import { planJsonLogicService } from "../../../app/services/plan.jsonLogic.service";
import { PLAN_JSON_LOGIC_KEYS } from "../../../app/constants";
import STypography from "../../../components/SMui/STypography";

class EmptyState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayEnterpriseLink: false,
    };
  }

  componentDidMount() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
      {},
      (response) => {
        const enterpriseFeatureIsDisabled = response.status === "error";
        this.setState({
          displayEnterpriseLink: !enterpriseFeatureIsDisabled,
        });
      },
      (error) => {}
    );
  }
  render() {
    const { t } = this.props;
    return (
      <div className="central-content">
        <div
          style={{ paddingTop: "38px" }}
          className="empty-state-content display-flex justify-content-space-between responsive-emptyState"
        >
          <div
            id="EmptyState-box"
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "40px",
              gap: "35px",
            }}
          >
            <STypography
              sx={{ fontSize: "24px", fontWeight: 700, color: "#2D3748" }}
            >
              {t("Welcome to your new project!")}
            </STypography>
            <STypography
              sx={{
                maxWidth: "352px",
                fontSize: "18px",
                fontWeight: 400,
                color: "#4A5568",
              }}
            >
              {t("Add a data source to unlock the full power of Sensia.")}
            </STypography>

            <button
              onClick={this.props.handleClickSearchDatasouce}
              className="button blue"
              style={{ textAlign: "center", width: "151px" }}
            >
              {t("Find Data Source")}
            </button>

            <div className="font-inter-400 font-size-15 color-000 text-align-left line-height-1-5 display-flex align-items-center gap-7">
              {t("Already have data? ")}

              <STypography
                onClick={() => this.props.handleClickAddDatasouce()}
                sx={{
                  color: "#306ED6",
                  fontSize: "15px",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                {t("Upload a File or Add an URL")}
              </STypography>
            </div>
          </div>
          <div style={{ width: "70%" }}>
            <img alt="" src={imgEmptyState} className="img-100" />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EmptyState);
