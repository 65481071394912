import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "../app/constants";

export default function CountrySelect({
  title = null,
  sx = { width: 300 },
  onChange = () => null,
  displayPhone = false,
  value = null,
  availableCountries = null,
}) {
  let currentValue = null;
  if (value) {
    currentValue = countries.find((country) => country.code === value);
  }

  let filteredCountries = Object.assign([], countries);
  if (
    availableCountries &&
    availableCountries.length > 0 &&
    availableCountries[0] !== "ALL"
  ) {
    filteredCountries = countries.filter((country) =>
      availableCountries.includes(country.code)
    );
  }
  return (
    <Autocomplete
      id="country-select-demo"
      sx={sx}
      options={filteredCountries.sort((a, b) =>
        a.suggested === b.suggested ? 0 : a.suggested ? -1 : 1
      )} // Tri pour que les suggérés viennent en premier
      groupBy={(option) => (option.suggested ? "Most popular search" : "")} // Groupe les options suggérées sous le titre
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={(event, values) => onChange(values)}
      value={currentValue}
      renderOption={(props, option) => (
        <Box
          {...props}
          key={option.label}
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
        >
          <img
            key={`img-${option.label}`}
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) {displayPhone && `+ ${option.phone}`}
        </Box>
      )}
      renderGroup={(params) => (
        <>
          {params.group === "Most popular search" && (
            <Box
              key={`group-${params.key}`}
              sx={{ padding: "8px 16px", fontWeight: "bold" }}
            >
              {params.group}
            </Box>
          )}
          {params.group !== "Most popular search" && (
            <hr
              style={{
                margin: "8px 0",
                border: "none",
                borderTop: "1px solid #ccc",
              }}
            />
          )}
          <Box key={params.key}>{params.children}</Box>
        </>
      )}
      renderInput={(params) => {
        // Recherche du pays sélectionné dans filteredCountries par le label
        const selectedCountry = filteredCountries.find(
          (country) => country.label === params.inputProps.value
        );

        return (
          <TextField
            {...params}
            key={`input-${params.key}`}
            sx={{ fontFamily: "Inter" }}
            label={title}
            placeholder="Country"
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              startAdornment: selectedCountry ? (
                // Si un pays est trouvé, affiche son drapeau
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                  alt=""
                  style={{ marginLeft: 3, marginRight: 3, marginBottom: 3 }}
                />
              ) : null,
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        );
      }}
      isOptionEqualToValue={(option, value) => option.code === value.code}
    />
  );
}
