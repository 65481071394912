import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  ACCOUNT_PLAN,
  PLAN_JSON_LOGIC_KEYS,
  globalConstants,
} from "../../app/constants";
import Header from "../../components/header/Header";

import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import UserHelper from "../../app/helpers/UserHelper";

//import "./tabViews/overview/OverView.css";

import IntercomHelper from "../../app/helpers/IntercomHelper";
import { Box, Button, Tab, Tabs, Typography, styled } from "@mui/material";
import ProjectDetailView from "../projectDetail/ProjectDetailView";
import ProjectNewInsight from "../projectNewInsight/projectNewInsight";
import ProjectReportView from "../projectReport/ProjectReportView";
import ProjectWorkspaceView from "../projectWorkspace/ProjectWorkspaceView";
import {
  PROJECT_ADD_MEDIA,
  PROJECT_DETAIL,
  PROJECT_SEARCH_MEDIA,
  ROUTES,
} from "../../app/routing";
import { ProjectDataManager } from "../../dataManagers/projectDataManager";

import EditProjectModal from "../../components/modal/EditProjectModal";
import DeleteModal from "../../components/modal/DeleteModal";
import Loader from "../../components/loader/Loader";
import { SessionStorageService } from "../../app/services/storage.service";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";

import Close from "../../res/images/icons/pic-tag-clear.svg";
import STypography from "../../components/SMui/STypography";
import ReviewsRoundedIcon from "@mui/icons-material/ReviewsRounded";
import illus_quotas from "../../res/images/illustrations/illus-quotas-popup.svg";
import Link from "../../components/Link";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#000",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#9e9e9e",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function getDefaultTab(search) {
  const urlParams = HttpHelper.getUrlParameters(search);
  if (urlParams && urlParams.hasOwnProperty("tab")) {
    return urlParams.tab;
  }

  return "overview";
}

function getDefaultDatasource(search) {
  const urlParams = HttpHelper.getUrlParameters(search);
  if (urlParams && urlParams.hasOwnProperty("datasourceId")) {
    return parseInt(urlParams.datasourceId);
  }

  return null;
}

class ProjectView extends React.Component {
  constructor(props) {
    super(props);
    const projectId = props.match.params.id
      ? parseInt(props.match.params.id)
      : null;

    this.state = {
      projectId: projectId,
      datasourceId: getDefaultDatasource(props.location.search),
      project: null,
      tabValue: getDefaultTab(props.location.search), // first tab value
      workspaceDefaultKeywords: [],
      workspaceDefaultClassId: null,
      displayOriginalReviews: false,
      editProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      deleteProject: {
        display: false,
        loading: false,
        error: null,
      },
      ////////
      enterpriseFeatureIsDisabled: true,
      currentPlan: null,
      planPopup: false,
      quotasExceeded: false,
      quotasPopup: false,
      quotasPopupData: {
        totalReviews: null,
        maxReviews: null,
        isUpdate: false,
      },
    };

    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.renderTabChildren = this.renderTabChildren.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.pageTitleHandler = this.pageTitleHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onOpenEditProjectModal = this.onOpenEditProjectModal.bind(this);
    this.onCloseEditProjectModal = this.onCloseEditProjectModal.bind(this);
    this.onEditProject = this.onEditProject.bind(this);

    this.checkAddDatasourceRules = this.checkAddDatasourceRules.bind(this);

    this.handleClickAddDatasource = this.handleClickAddDatasource.bind(this);
    this.handleClickSearchDatasource =
      this.handleClickSearchDatasource.bind(this);

    this.getPlanPopup = this.getPlanPopup.bind(this);
    this.closePlanPopup = this.closePlanPopup.bind(this);

    this.openQuotasPopup = this.openQuotasPopup.bind(this);
    this.getQuotasPopUp = this.getQuotasPopUp.bind(this);
    this.closeQuotasPopUp = this.closeQuotasPopUp.bind(this);

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    SessionStorageService.set("update_media_referrer", props.location.pathname);

    IntercomHelper.updateIntercom();
  }

  componentDidMount() {
    this._ismounted = true;
    this.checkAddDatasourceRules();
    this.fetchProject();
  }

  componentWillUnmount() {
    this._ismounted = false;
    this.abortController.abort();
  }

  async checkAddDatasourceRules(numberOfReviews = 0, callback = null) {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
      {
        project_id: this.state.projectId,
        other_datas: { new_datasource_nb_reviews: numberOfReviews },
      },
      (response) => {
        const max = response.data.max_reviews_for_account;
        const total = response.current_data.current_reviews_for_account;

        this.setState(
          {
            quotasExceeded: response.status === "error",
            currentPlan: response.data.plan,
            enterpriseFeatureIsDisabled: ![
              ACCOUNT_PLAN.ENTERPRISE,
              ACCOUNT_PLAN.ENTERPRISE_SAAS,
              ACCOUNT_PLAN.MANUAL_BILLING,
            ].includes(response.data.plan),
            quotasPopupData: {
              totalReviews: total,
              maxReviews: max,
              isUpdate: false,
            },
          },
          () => {
            // Appel du callback avec la valeur de response.status après la mise à jour du state
            if (callback) {
              callback(response.status);
            }
          }
        );
      },
      (error) => {
        console.error(error);
        if (callback) {
          callback("error");
        }
      }
    );
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        const accounts = UserHelper.getAllUserAccounts().filter(
          (account) => account.id === response.accountId
        );
        if (accounts && accounts.length)
          UserHelper.updateCurrentAccountById(response.accountId);

        this.setState({
          project: response,
        });
      },
      (error) => {
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "overview" },
      this.abortControllerSignal
    );
  }

  pageTitleHandler() {
    return `${this.state.project ? this.state.project.name + " -" : ""} ${
      globalConstants.WEBSITE_TITLE
    }`;
  }

  handleChange(event, newValue) {
    window.scrollTo(0, 0);
    let datasourceId = this.state.datasourceId;
    if (newValue == "overview") {
      datasourceId = null;
    }
    this.setState({ tabValue: newValue, datasourceId: datasourceId }, () => {
      this.updateUrl();
    });
  }

  updateUrl() {
    let search = "?tab=";
    search = search + this.state.tabValue;
    if (this.state.datasourceId) {
      search = search + "&datasourceId=" + this.state.datasourceId;
    }

    this.props.history.replace({
      pathname: PROJECT_DETAIL(this.state.projectId),
      search: search,
    });
  }

  // EDIT PROJECT
  onOpenEditProjectModal() {
    this.setState({
      editProject: {
        display: true,
        item: this.state.project,
        loading: false,
        error: null,
      },
    });
  }

  onCloseEditProjectModal() {
    this.setState({
      editProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onEditProject(formData) {
    let editProjectData = Object.assign({}, this.state.editProject);
    if (editProjectData.item) {
      editProjectData.loading = true;

      this.setState({
        editProject: editProjectData,
      });

      projectService.put.update_name(
        this.state.projectId,
        formData,
        (response) => {
          this.props.addToast("Project name successfully updated", {
            type: "success",
            autoDismiss: true,
          });
          this.setState({
            editProject: {
              display: false,
              item: null,
              loading: false,
              error: null,
            },
            project: response,
          });
        },
        (error) => {
          editProjectData.loading = false;
          this.displayToastErrorMessage(error, null, {
            editProject: editProjectData,
          });
        }
      );
    }
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  handleClickAddDatasource() {
    if (this.state.quotasExceeded) {
      this.setState({ quotasPopup: true });
    } else {
      this.props.history.push(PROJECT_ADD_MEDIA(this.state.projectId));
    }
  }

  handleClickSearchDatasource() {
    if (this.state.quotasExceeded) {
      this.setState({ quotasPopup: true });
    } else {
      this.props.history.push(PROJECT_SEARCH_MEDIA(this.state.projectId));
    }
  }

  renderTabChildren(tabValue) {
    if (tabValue === "overview") {
      return (
        <ProjectDetailView
          key="overview"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          updateProject={(project) => this.setState({ project: project })}
          selectDatasourceId={(datasourceId, tab = "insights") =>
            this.setState(
              {
                datasourceId: datasourceId,
                tabValue: tab,
              },
              () => {
                this.updateUrl();
              }
            )
          }
          handleClickAddDatasource={this.handleClickAddDatasource}
          handleClickSearchDatasource={this.handleClickSearchDatasource}
          onOpenQuotasPopup={this.openQuotasPopup}
        />
      );
    } else if (tabValue === "insights") {
      return (
        <ProjectNewInsight
          key="insights"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          planKey={this.state.currentPlan}
          handleClickAddDatasource={this.handleClickAddDatasource}
          handleClickSearchDatasource={this.handleClickSearchDatasource}
          selectDatasourceId={(datasourceId) =>
            this.setState({ datasourceId: datasourceId }, () => {
              this.updateUrl();
            })
          }
        />
      );
    } else if (tabValue === "in-depth") {
      return (
        <ProjectReportView
          key="in-depth"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          planKey={this.state.currentPlan}
          handleClickAddDatasource={this.handleClickAddDatasource}
          handleClickSearchDatasource={this.handleClickSearchDatasource}
          selectDatasourceId={(datasourceId) =>
            this.setState({ datasourceId: datasourceId }, () => {
              this.updateUrl();
            })
          }
          openWorkspaceWithKeywords={(keywords) => {
            this.setState(
              {
                workspaceDefaultKeywords: keywords,
                tabValue: "inspect",
              },
              () => {
                this.updateUrl();
              }
            );
          }}
        />
      );
    } else if (tabValue === "inspect") {
      return (
        <ProjectWorkspaceView
          key="inspect"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          planKey={this.state.currentPlan}
          defaultKeywords={this.state.workspaceDefaultKeywords}
          defaultClassId={this.state.workspaceDefaultClassId}
          displayOriginalReviews={this.state.displayOriginalReviews}
          handleClickAddDatasource={this.handleClickAddDatasource}
          handleClickSearchDatasource={this.handleClickSearchDatasource}
          updateDisplayOriginalReviews={(val) =>
            this.setState({ displayOriginalReviews: val })
          }
          updateKeywordsSelection={(keywords) =>
            this.setState({ workspaceDefaultKeywords: keywords })
          }
          selectDatasourceId={(datasourceId) => {
            this.setState(
              {
                datasourceId: datasourceId,
              },
              () => {
                this.updateUrl();
              }
            );
          }}
        />
      );
    }

    return <Loader />;
  }

  closePlanPopup() {
    this.setState({
      planPopup: false,
    });
  }

  getPlanPopup() {
    const { t } = this.props;
    return (
      <div className="position-absolute share-tooltip-free">
        <img
          onClick={this.closePlanPopup}
          className="close"
          src={Close}
          alt=""
        />

        <h3>Upgrade your Plan</h3>

        <p style={{ letterSpacing: "0.15px" }}>
          You are currently on an {this.state.currentPlan} plan. To access the
          search data source feature, please upgrade to the Enterprise plan.
        </p>

        <Link to={ROUTES.PLAN_CHOOSE} type="a" target="_blank">
          <span className="actions color-3a79e6">
            See what you get when you upgrade
          </span>
        </Link>

        <div className="btn-box" style={{ marginTop: "40px" }}>
          <div className="content" style={{ justifyContent: "flex-end" }}>
            <span
              className="actions color-3a79e6"
              onClick={this.closePlanPopup}
            >
              {t("Maybe later")}
            </span>
            <span onClick={this.closePlanPopup} className="button">
              <Link
                to={ROUTES.PLAN_CHOOSE}
                style={{ textDecoration: "none", color: "white" }}
              >
                Upgrade
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }

  closeQuotasPopUp() {
    const popupData = Object.assign({}, this.state.quotasPopupData);
    popupData.isUpdate = false;
    this.setState({
      quotasPopup: false,
      quotasPopupData: popupData,
    });
  }

  openQuotasPopup(quotasPopupData) {
    this.setState({
      quotasPopup: true,
      quotasPopupData: quotasPopupData,
    });
  }

  getQuotasPopUp() {
    const { t } = this.props;
    const { quotasPopupData, enterpriseFeatureIsDisabled } = this.state;

    const totalReviews = quotasPopupData.totalReviews;
    const maxReviews = quotasPopupData.maxReviews;
    const isUpdate = quotasPopupData.isUpdate;
    const isAdmin = UserHelper.isAdmin();
    let exceedPercent = ((totalReviews - maxReviews) / maxReviews) * 100;
    exceedPercent = exceedPercent?.toFixed();

    return (
      <div className="position-absolute sensia-popup">
        <img
          onClick={this.closeQuotasPopUp}
          className="close"
          src={Close}
          alt=""
        />

        <Box sx={{ display: "flex", gap: "10px" }}>
          <ReviewsRoundedIcon sx={{ color: "#FF9378" }} />
          <STypography sx={{ fontSize: "18px" }}>
            {t("Storage Limit Exceed")}
          </STypography>
        </Box>

        <Box
          sx={{
            mt: "32px",
            display: "flex",
            alignItems: "center",
            gap: "28px",
          }}
        >
          <Box>
            <STypography sx={{ fontSize: "24px", fontWeight: 700 }}>
              <Trans i18nKey="hit_storage_media_limit_warning">
                You have exceeded your limit by {{ exceedPercent }}%
              </Trans>
            </STypography>

            <STypography
              sx={{
                mt: "15px",
                fontSize: "18px",
                fontWeight: 400,
                color: "#757575",
              }}
            >
              {isUpdate &&
                isAdmin &&
                t(
                  "To update your sources and continue benefiting from the latest updates, you need to increase your storage"
                )}
              {isUpdate &&
                !isAdmin &&
                t(
                  "Free up space to update your sources and continue benefiting from the latest updates."
                )}
              {!isUpdate &&
                isAdmin &&
                t(
                  "To add new data and continue receiving updates, you need to increase your storage"
                )}
              {!isUpdate &&
                !isAdmin &&
                t(
                  "Free up space to add new data and continue receiving updates and stay updated."
                )}
            </STypography>
          </Box>
          <img src={illus_quotas} alt="illustration-quotas" />
        </Box>

        {isAdmin && enterpriseFeatureIsDisabled && (
          <Button
            onClick={() =>
              this.props.history.push(ROUTES.SETTINGS_PLAN_AND_BILLING_OVERVIEW)
            }
            sx={{
              mt: "30px",
              borderRadius: "6px",
              background: "#F78B7C",
              color: "#FFF",
              textTransform: "none",
              "&:hover": {
                background: "#F78B7A",
              },
            }}
          >
            Add more Reviews
          </Button>
        )}
        {isAdmin && !enterpriseFeatureIsDisabled && (
          <Button
            onClick={() => IntercomHelper.trackEvent("contact-success-team")}
            sx={{
              mt: "30px",
              borderRadius: "6px",
              background: "#F78B7C",
              color: "#FFF",
              textTransform: "none",
              "&:hover": {
                background: "#F78B7A",
              },
            }}
          >
            Contact Our Success Team
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { tabValue, project } = this.state;
    let page_title = this.pageTitleHandler();

    return (
      <Fragment>
        <Header isProject={true} />
        <Helmet>
          <title>{page_title}</title>
        </Helmet>
        <div className="topTab responsive-920-padding-top-20">
          <div className="central-content">
            <div className="position-relative">
              <Box sx={{ width: "95%" }}>
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                  }}
                >
                  {project?.name}
                </Typography>
              </Box>
            </div>
            <div className="position-relative">
              <Box sx={{ width: "95%", mt: "6px" }}>
                <Typography
                  sx={{
                    color: "#616161",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  {project?.type_name}
                </Typography>
              </Box>
            </div>
            <Box
              sx={{
                width: "100%",
                mt: "20px",
                position: "sticky",
                top: "60px",
                zIndex: "18",
              }}
            >
              {/* full width grey background */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100vw",
                  height: "100%",
                  background: "#f8f8f8",
                }}
              />
              <StyledTabs
                value={tabValue}
                onChange={this.handleChange}
                aria-label="project detail tabs"
              >
                <StyledTab value="overview" label="Overview" />
                <StyledTab value="insights" label="Insights" />
                <StyledTab value="in-depth" label="In-Depth" />
                <StyledTab value="inspect" label="Inspect" />
              </StyledTabs>
            </Box>

            <ProjectDataManager>
              {this.renderTabChildren(tabValue)}
            </ProjectDataManager>
          </div>
        </div>
        {this.state.editProject.display && (
          <EditProjectModal
            onClose={this.onCloseEditProjectModal}
            loading={this.state.editProject.loading}
            item={this.state.editProject.item}
            onEditItem={this.onEditProject}
          />
        )}

        {this.state.planPopup && (
          <Fragment>
            <div id="fond"></div>
            {this.getPlanPopup()}
          </Fragment>
        )}
        {this.state.quotasPopup && (
          <Fragment>
            <div id="fond"></div>
            {this.getQuotasPopUp()}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ProjectView));
